import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { useAppStore } from '@/stores';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

// eslint-disable-next-line complexity
const ApplicationBreadcrumbs = () => {
  const pathname = useLocation().pathname;
  const search = useLocation().search;
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    return null;
  }

  const [, root, ...rest] = pathname.split('/');

  const breadcrumbs: Array<{ label: string; to?: string }> = [];

  if (root === 'account') {
    breadcrumbs.push({ label: 'My Account', to: '/account' });

    switch (rest[0]) {
      case 'profile':
        breadcrumbs.push({ label: 'Manage Profile' });
        break;
      case 'contact':
        breadcrumbs.push({ label: 'Manage Contac1t Details' });
        break;
      case 'memberships':
        breadcrumbs.push({ label: 'Manage Memberships' });
        break;
      case 'notifications':
        breadcrumbs.push({ label: 'View All Notifications' });
        break;
      case 'close':
        breadcrumbs.push({ label: 'Close' });
        break;
      default:
      // noop
    }
  } else if (root === 'home') {
    breadcrumbs.push({ label: 'Home', to: '/home' });
  } else if (root === 'dashboard') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'Dashboard' });

    if (rest[0]) {
      breadcrumbs.push({ label: activeMembership.name });
    }
  } else if (root === 'check-in') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'Check In', to: '/check-in' });

    if (rest[0] === undefined) {
      breadcrumbs.push({ label: 'All' });
    }

    if (rest[0]?.length === 26) {
      breadcrumbs.push({ label: 'Details' });
    }
  }

  if (root === 'check-in-reports') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'Check In Reports', to: '/check-in-reports' });

    if (rest[0] === undefined) {
      breadcrumbs.push({ label: 'All' });
    }

    if (rest[0] === 'new') {
      breadcrumbs.push({ label: 'New' });
    }

    if (rest[0]?.length === 26) {
      breadcrumbs.push({ label: 'Details' });
    }
  }

  if (root === 'guardme') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'GuardMe', to: '/guardme' });

    if (rest[0] === undefined) {
      breadcrumbs.push({ label: 'All' });
    }

    if (rest[0]?.length === 26) {
      breadcrumbs.push({ label: 'Details' });
    }
  }

  if (root === 'incidents') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'Incidents', to: '/incidents' });

    if (rest[0] === undefined) {
      if (search.includes('view=assignedToMyGroup')) {
        breadcrumbs.push({ label: 'Assigned to My Group' });
      } else if (search.includes('view=submittedByMe')) {
        breadcrumbs.push({ label: 'Submitted By Me' });
      } else {
        breadcrumbs.push({ label: 'All' });
      }
    }

    if (rest[0] === 'new') {
      breadcrumbs.push({ label: 'New' });
    }

    if (rest[0]?.length === 26) {
      breadcrumbs.push({ label: 'Details' });
    }
  }

  if (root === 'members') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });

    if (rest[0] === undefined) {
      breadcrumbs.push({ label: 'Members' });
    }

    if (rest[0] === 'invitations') {
      breadcrumbs.push({ label: 'Members', to: '/members' });
      breadcrumbs.push({ label: 'Invitation Details' });
    }

    if (rest[0] === 'requests') {
      breadcrumbs.push({ label: 'Members', to: '/members' });
      breadcrumbs.push({ label: 'Membership Request Details' });
    }

    if (rest[0] === 'groups') {
      breadcrumbs.push({ label: 'Members', to: '/members' });
      breadcrumbs.push({ label: 'Group Details' });
    }

    if (rest[0]?.length === 26) {
      breadcrumbs.push({ label: 'Members', to: '/members' });
      breadcrumbs.push({ label: 'Details' });
    }
  }

  if (root === 'settings') {
    breadcrumbs.push({ label: activeMembership.name, to: '/dashboard' });
    breadcrumbs.push({ label: 'Settings' });
  }

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        {breadcrumbs.map((item, index) => {
          if (item.to === undefined) {
            return (
              <Fragment key={item.label}>
                {Boolean(index) && <BreadcrumbSeparator />}
                <BreadcrumbItem>
                  <BreadcrumbPage>{item.label}</BreadcrumbPage>
                </BreadcrumbItem>
              </Fragment>
            );
          } else {
            return (
              <Fragment key={item.label}>
                {Boolean(index) && <BreadcrumbSeparator />}
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to={item.to}>{item.label}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Fragment>
            );
          }
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export { ApplicationBreadcrumbs };
