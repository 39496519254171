import { MutationError } from '../../Misc';
import { DeleteInvitationDialog } from '../components/DeleteInvitationDialog';
import { GroupsSelect } from '../components/GroupsSelect';
import { ClientError, Loader } from '@/components';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { parseMetadata } from '@/lib/metadata';
import { fmtPhoneNumber } from '@/utils';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberInvitationGql = graphql(`
  query MemberInvitationGql($id: String!) {
    invitation(id: $id) {
      id
      key
      organizationRole
      groupIds
      metadata
      organization {
        id
        name
        memberMetadataFields
        groups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

// eslint-disable-next-line complexity
const MemberInvitation = () => {
  const navigate = useNavigate();

  const { id } = useParams() as { id: string };

  const [{ data, error, fetching }] = useQuery({
    query: MemberInvitationGql,
    variables: { id },
  });

  const dynamicSchema = parseMetadata(
    data?.invitation?.organization?.memberMetadataFields,
    data?.invitation?.metadata,
  );

  return (
    <div className="grid flex-1 items-start gap-4 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <Link to="/members">
            <Button
              className="h-7 w-7"
              size="icon"
              variant="outline"
            >
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
          </Link>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-semibold tracking-tight sm:grow-0">
            {data?.invitation ? (
              data.invitation.key
            ) : (
              <Skeleton className="bg-muted-foreground/10 h-8 my-0.5 w-[250px]" />
            )}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            {data?.invitation && (
              <DeleteInvitationDialog
                identifer={data.invitation.key}
                invitationId={data.invitation.id}
                onComplete={() => {
                  navigate('/members');
                }}
              />
            )}
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <Loader
            isLoading={fetching}
            loadingError={error}
          >
            {data?.invitation ? (
              <>
                <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                  <MutationError error={error} />

                  <Card>
                    <CardHeader>
                      <CardTitle>Details</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-6">
                      <div>
                        <Label>
                          {data.invitation.key.startsWith('+')
                            ? 'Phone Number'
                            : 'Email Address'}
                        </Label>
                        <Input
                          readOnly
                          value={
                            data.invitation.key.startsWith('+')
                              ? fmtPhoneNumber(data.invitation.key)
                              : data.invitation.key
                          }
                        />
                      </div>

                      <div>
                        <Label>Role</Label>
                        <Select
                          defaultValue={data.invitation.organizationRole}
                          disabled
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select a role" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={MemberRole.Member}>
                              Member
                            </SelectItem>
                            <SelectItem value={MemberRole.Supervisor}>
                              Supervisor
                            </SelectItem>
                            <SelectItem value={MemberRole.Administrator}>
                              Admin
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div>
                        <Label>Groups</Label>
                        <GroupsSelect
                          groups={
                            data.invitation.organization?.groups.nodes ?? []
                          }
                          name="Groups"
                          onChange={() => {}}
                          readOnly
                          value={data.invitation.groupIds as string[]}
                        />
                      </div>

                      {dynamicSchema.formFields.map((item) => (
                        <div key={item.id}>
                          {item.type === 'STRING' ? (
                            <>
                              <Label>{item.name}</Label>
                              <Input
                                onChange={() => {}}
                                readOnly
                                value={
                                  dynamicSchema.defaultValues[
                                    item.name
                                  ] as string
                                }
                              />
                            </>
                          ) : (
                            <>
                              <Label>{item.name}</Label>
                              <Checkbox
                                checked={
                                  dynamicSchema.defaultValues[
                                    item.name
                                  ] as boolean
                                }
                                disabled
                                onChange={() => {}}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </div>

                <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-4">
                    <div className="sm:col-span-1">
                      <dt className="font-medium text-muted-foreground">
                        Organization Name
                      </dt>
                      <dd className="mt-1">
                        {data.invitation.organization?.name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-muted-foreground">Invitation Id</dt>
                      <dd className="mt-1">{id}</dd>
                    </div>
                  </dl>
                </div>
              </>
            ) : (
              <ClientError
                code={404}
                message="Member does not exist"
              />
            )}
          </Loader>
        </div>
      </div>
    </div>
  );
};

export { MemberInvitation };
