import { ClientError } from '@/components';
import { useAppStore } from '@/stores';
import { Outlet } from 'react-router-dom';

const OrganizationSettingsLayout = () => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  if (!isAdmin) {
    return <ClientError code={401} />;
  }

  return <Outlet />;
};

export { OrganizationSettingsLayout };
