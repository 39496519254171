import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import { type Membership } from '@/stores/types';
import { ChevronsUpDown, Plus } from 'lucide-react';
import { Link } from 'react-router-dom';

const OrganizationSwitcher = ({
  activeOrganization,
  organizations,
  setActiveOrganization,
}: {
  readonly activeOrganization: Membership | null;
  readonly organizations: Membership[];
  readonly setActiveOrganization: (id: string) => void;
}) => {
  const { isMobile } = useSidebar();

  if (!activeOrganization) {
    return null;
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              size="lg"
            >
              <Avatar className="size-8 rounded-lg">
                <AvatarImage
                  alt={activeOrganization.name}
                  src={activeOrganization.logoUrl}
                />
                <AvatarFallback className="rounded-lg">
                  {activeOrganization.name.slice(0, 1)}
                </AvatarFallback>
              </Avatar>

              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {activeOrganization.name}
                </span>
                <span className="truncate text-xs">
                  {activeOrganization.plan}
                </span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            className="w-[--radix-dropdown-menu-trigger-width] min-w-64 rounded-lg"
            side={isMobile ? 'bottom' : 'right'}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Organizations
            </DropdownMenuLabel>
            {organizations.map((organization) => (
              <DropdownMenuItem
                className="gap-2 p-2"
                key={organization.name}
                onClick={() => setActiveOrganization(organization.id)}
              >
                <Avatar className="size-6 rounded-lg">
                  <AvatarImage
                    alt={organization.name}
                    src={organization.logoUrl}
                  />
                  <AvatarFallback className="rounded-lg">
                    {organization.name.slice(0, 1)}
                  </AvatarFallback>
                </Avatar>

                {organization.name}
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              asChild
              className="gap-2 p-2"
            >
              <Link to="/organization/new">
                <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                  <Plus className="size-4" />
                </div>
                <div className="font-medium text-muted-foreground">
                  Add organization
                </div>
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

export { OrganizationSwitcher };
