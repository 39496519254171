import { MapSettingsForm } from '../components/MapSettingsForm';
import { Loader } from '@/components';
import { MapProvider } from '@/components/Map';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const MapSettingsGql = graphql(`
  query MapSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      defaultMapCenter {
        longitude
        latitude
      }
      defaultMapZoom
    }
  }
`);

const MapSettings = () => {
  const [organizationId] = useAppStore((state) => [state.activeMembership?.id]);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: MapSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Place Settings
      </h1>
      <p className="mt-1 text-sm">
        Configure the default map used by your organization.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization && (
          <MapProvider>
            <MapSettingsForm
              defaultMapCenter={{
                lat: data.organization.defaultMapCenter.latitude,
                lng: data.organization.defaultMapCenter.longitude,
              }}
              defaultMapZoom={data.organization.defaultMapZoom}
              organizationId={organizationId}
            />
          </MapProvider>
        )}
      </Loader>
    </>
  );
};

export { MapSettings };
