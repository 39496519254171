import { DeleteCustomMetadataFieldDialog } from '../components/DeleteCustomMetadataFieldDialog';
import { NewCustomMetadataFieldDialog } from '../components/NewCustomMetadataFieldDialog';
import { Empty, Loader } from '@/components';
import { Separator } from '@/components/ui/separator';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Eye, Star } from 'lucide-react';
import { useQuery } from 'urql';

const OrganizationCustomMemberMedataQueryDocument = graphql(`
  query OrganizationCustomMemberMedata($organizationId: String!) {
    organization(id: $organizationId) {
      id
      memberMetadataFields
    }
  }
`);

type MetadataField = {
  defaultValue: boolean | number | string;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: string;
  value: string;
};

const metadataToArray = (records?: Record<string, MetadataField> | null) => {
  if (!records) {
    return [];
  }

  const nodes: MetadataField[] = [];
  for (const value of Object.values(records)) {
    nodes.push(value);
  }

  return nodes;
};

const CustomMetadata = () => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin,
  ]);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }, refetch] = useQuery({
    query: OrganizationCustomMemberMedataQueryDocument,
    variables: {
      organizationId,
    },
  });

  const metadataRows = metadataToArray(
    data?.organization?.memberMetadataFields,
  );

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Custom Metadata
      </h1>
      <p className="mt-1 text-sm">
        You can add custom fields to your members to capture additional
        information.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization && (
          <>
            <NewCustomMetadataFieldDialog
              existingFields={data?.organization?.memberMetadataFields}
              isAdmin={isAdmin}
              onComplete={() => refetch({ requestPolicy: 'network-only' })}
              organizationId={organizationId}
            />

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Default</TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {metadataRows.length > 0 ? (
                  metadataRows.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell className="font-bold">
                        <div className="flex items-center gap-2">
                          {item.name}
                          {item.isRequired && (
                            <Star className="h-4 w-4 text-red-500" />
                          )}
                          {item.isPrivate && (
                            <Eye className="h-4 w-4 text-primary" />
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.defaultValue}</TableCell>
                      <TableCell className="text-right">
                        <DeleteCustomMetadataFieldDialog
                          fieldName={item.name}
                          isAdmin={isAdmin}
                          memberMetadataFields={
                            data?.organization?.memberMetadataFields
                          }
                          organizationId={organizationId}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Empty message="You can add additional member information by creating a custom member field." />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <ol className="space-y-2">
              <li className="flex items-center truncate mr-2">
                <Eye className="h-4 w-4 mr-2 text-primary" />
                <p className="text-sm whitespace-nowrap truncate text-muted-foreground">
                  Private fields will remain hidden from members.
                </p>
              </li>
              <li className="flex items-center truncate mr-2">
                <Star className="h-4 w-4 mr-2 text-red-500" />
                <p className="text-sm whitespace-nowrap truncate text-muted-foreground">
                  Required Fields must contain a value.
                </p>
              </li>
            </ol>
          </>
        )}
      </Loader>
    </div>
  );
};

export { CustomMetadata };
