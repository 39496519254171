import { DeleteMembershipRequestsCodeDialog } from '../components/DeleteMembershipRequestsCodeDialog';
import { NewMembershipCodeDialog } from '../components/NewMembershipCodeDialog';
import { Empty, Loader, Spinner } from '@/components';
import { Datetime } from '@/components/Datetime';
import { Separator } from '@/components/ui/separator';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { normalizeMemberRole, OrganizationNotFoundError } from '@/utils';
import { CircleCheck } from 'lucide-react';
import { useQuery } from 'urql';

const MembershipRequestsCodesQuery = graphql(`
  query MembershipRequestsCodesQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      groups {
        totalCount
        nodes {
          id
          name
        }
      }
      membershipCodes {
        totalCount
        nodes {
          id
          code
          groupIds
          organizationRole
          requiresApproval
          createdAt
        }
      }
    }
  }
`);

const MembershipCodes = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }, refetch] = useQuery({
    query: MembershipRequestsCodesQuery,
    variables: {
      organizationId,
    },
  });

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Membership Codes
      </h1>
      <p className="mt-1 text-sm">
        Create custom codes that members can use to join your organization. Each
        code can be used to help sort new members into default groups or roles.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization && (
          <>
            <NewMembershipCodeDialog
              groups={data.organization.groups.nodes}
              isAdmin={isAdmin}
              onComplete={() => refetch()}
              organizationId={organizationId}
            />

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Role</TableHead>
                  <TableHead>Groups</TableHead>
                  <TableHead className="text-center">
                    Requires Approval?
                  </TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {fetching ? (
                  <TableRow>
                    <TableCell
                      className="h-24 text-center"
                      colSpan={6}
                    >
                      <Spinner title="Loading..." />
                    </TableCell>
                  </TableRow>
                ) : data?.organization?.membershipCodes.nodes.length > 0 ? (
                  data.organization.membershipCodes.nodes.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="font-bold">{item.code}</TableCell>
                      <TableCell>
                        <Datetime
                          datetime={item.createdAt}
                          format="MMM dd, yyyy"
                        />
                      </TableCell>
                      <TableCell>
                        {normalizeMemberRole(item.organizationRole)}
                      </TableCell>
                      <TableCell>
                        {item.groupIds.map((group) => {
                          return group
                            ? data.organization?.groups.nodes.find(
                                (subitem) => {
                                  return subitem.id === group;
                                },
                              )?.name
                            : '';
                        }) ?? '---'}
                      </TableCell>
                      <TableCell>
                        {item.requiresApproval && (
                          <CircleCheck className="mx-auto h-6 w-6 text-success-foreground" />
                        )}
                      </TableCell>
                      <TableCell className="text-right">
                        <DeleteMembershipRequestsCodeDialog
                          id={item.id}
                          isAdmin={isAdmin}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Empty message="Add a Membership Code to allow others to easily join your organization!" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </Loader>
    </div>
  );
};

export { MembershipCodes };
