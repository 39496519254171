import { NewGroupDialog } from './NewGroupDialog';
import { NewInivtationDialog } from './NewInivtationDialog';
import { Datetime } from '@/components/Datetime';
import { List, ListItem } from '@/components/List';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TableRowSkeleton } from '@/components/ui/TableWrapper';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { formatMemberRole } from '@/lib/formatGql';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberListGql = graphql(`
  query MemberListGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      memberMetadataFields
      groups {
        totalCount
        nodes {
          id
          name
          members {
            totalCount
          }
        }
      }
      invitations {
        totalCount
        nodes {
          id
          key
          organizationRole
        }
      }
      membershipRequests {
        totalCount
        nodes {
          id
          fullName
          avatarUrl
          phoneNumber
          emailAddress
          membershipCode {
            id
            organizationRole
          }
        }
      }
      members {
        totalCount
        nodes {
          id
          createdAt
          fullName
          displayName
          avatarUrl
          organizationRole
          lastActiveAt
          isEnabled
          emailAddress
          phoneNumber
          updatedAt
        }
      }
    }
  }
`);

type MemberListProps = {
  readonly organizationId: string;
};

const MemberList = ({ organizationId }: MemberListProps) => {
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const [{ data, error, fetching }] = useQuery({
    query: MemberListGql,
    requestPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });

  const invitationData =
    data?.organization?.invitations.nodes.sort((a, b) =>
      a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1,
    ) ?? [];

  const memberData =
    data?.organization?.members.nodes
      .map((member) => ({
        avatarUrl: member.avatarUrl ?? '',
        createdAt: member.createdAt,
        displayName: member.displayName ?? '',
        emailAddress: member.emailAddress ?? '',
        fullname: member.fullName ?? 'User deleted account',
        id: member.id,
        isEnabled: member.isEnabled,
        lastActive: member.lastActiveAt,
        phoneNumber: member.phoneNumber ?? '',
        role: member.organizationRole,
        search:
          member.displayName?.toLowerCase() +
          member.fullName?.toLowerCase() +
          member.emailAddress?.toLowerCase() +
          member.phoneNumber?.toLowerCase(),
        updatedAt: member.updatedAt,
      }))
      .filter((item) => item.search.includes(search.toLowerCase()))
      .sort((a, b) =>
        a.fullname.toLowerCase() < b.fullname.toLowerCase() ? -1 : 1,
      ) ?? [];

  const handleRowClick = (entityId: string) => {
    navigate(`/members/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 md:gap-8">
      <div className="grid">
        <div className="mb-4 flex items-end justify-between">
          <h1 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">
            Manage Members
          </h1>
        </div>

        <Tabs defaultValue="active">
          <TabsList>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="invitations">Invitations</TabsTrigger>
            <TabsTrigger value="inactive">Disabled</TabsTrigger>
            <TabsTrigger value="requests">Membership Requests</TabsTrigger>
            <TabsTrigger value="groups">Groups</TabsTrigger>
          </TabsList>
          <TabsContent value="active">
            <Input
              className="h-8 my-2"
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Filter Member..."
              value={search}
            />
            <Card className="sm:col-span-2">
              <CardContent className="p-0 grid gap-4">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead className="text-center">Role</TableHead>
                      <TableHead className="text-center">Last Active</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {error && <MutationError error={error} />}
                    {fetching && !data && (
                      <TableRowSkeleton
                        cols={3}
                        rows={6}
                      />
                    )}
                    {memberData
                      .filter((item) => item.isEnabled)
                      .map((item) => (
                        <TableRow
                          key={item.id}
                          onClick={() => handleRowClick(item.id)}
                        >
                          <TableCell>
                            <div className="flex items-center space-x-4">
                              <div className="relative">
                                <Avatar className="h-8 w-8">
                                  <AvatarImage src={item.avatarUrl} />
                                  <AvatarFallback>
                                    {item.fullname.slice(0, 2)}
                                  </AvatarFallback>
                                </Avatar>
                              </div>
                              <div>
                                <Link
                                  className="hover:underline"
                                  to={`/members/${item.id}`}
                                >
                                  {item.displayName || item.fullname}
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="text-center">
                            <Badge variant="secondary">
                              {formatMemberRole(item.role)}
                            </Badge>
                          </TableCell>
                          <TableCell className="text-center">
                            <Datetime
                              datetime={item.lastActive}
                              format="MMM dd yyyy"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="invitations">
            <Card className="sm:col-span-2">
              <CardHeader className="bg-muted rounded-t-lg flex flex-row justify-between items-center">
                <CardTitle>Invitations</CardTitle>
                <NewInivtationDialog organizationId={organizationId} />
              </CardHeader>
              <CardContent className="p-0 grid gap-4">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead className="text-center">Role</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {error && <MutationError error={error} />}
                    {fetching && !data && (
                      <TableRowSkeleton
                        cols={3}
                        rows={6}
                      />
                    )}
                    {invitationData.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell>
                          <div className="flex items-center space-x-4">
                            <div className="relative">
                              <Avatar className="h-8 w-8">
                                <AvatarFallback>
                                  {item.key.slice(0, 2)}
                                </AvatarFallback>
                              </Avatar>
                            </div>
                            <div>
                              <Link
                                className="hover:underline"
                                to={`/members/invitations/${item.id}`}
                              >
                                {item.key}
                              </Link>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="text-center">
                          <Badge variant="secondary">
                            {formatMemberRole(item.organizationRole)}
                          </Badge>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="inactive">
            <Input
              className="h-8 my-2"
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Filter Member..."
              value={search}
            />
            <Card className="sm:col-span-2">
              <CardContent className="p-0 grid gap-4">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead className="text-center">
                        Previous Role
                      </TableHead>
                      <TableHead className="text-center">Disabled On</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {error && <MutationError error={error} />}
                    {fetching && !data && (
                      <TableRowSkeleton
                        cols={3}
                        rows={6}
                      />
                    )}
                    {memberData
                      .filter((item) => !item.isEnabled)
                      .map((item) => (
                        <TableRow
                          key={item.id}
                          onClick={() => handleRowClick(item.id)}
                        >
                          <TableCell>
                            <div className="flex items-center space-x-4">
                              <div className="relative">
                                <Avatar className="h-8 w-8">
                                  <AvatarImage src={item.avatarUrl} />
                                  <AvatarFallback>
                                    {item.fullname.slice(0, 2)}
                                  </AvatarFallback>
                                </Avatar>
                              </div>
                              <div>
                                <Link
                                  className="hover:underline"
                                  to={`/members/${item.id}`}
                                >
                                  {item.displayName || item.fullname}
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="text-center">
                            <Badge variant="secondary">
                              {formatMemberRole(item.role)}
                            </Badge>
                          </TableCell>
                          <TableCell className="text-center">
                            <Datetime
                              datetime={item.updatedAt}
                              format="MMM dd yyyy"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="requests">
            <Card>
              <CardHeader className="bg-muted rounded-t-lg">
                <CardTitle className="text-xl flex items-center">
                  Membership Requests
                </CardTitle>
                <CardDescription>
                  Manage your{' '}
                  <Link
                    className="text-primary hover:underline"
                    to="/settings/membership-codes"
                  >
                    membership codes
                  </Link>{' '}
                  from settings.
                </CardDescription>
              </CardHeader>

              <List>
                {fetching && (
                  <>
                    <ListItem className="relative px-6 border-t">
                      <Skeleton className="h-8 w-full" />
                    </ListItem>
                    <ListItem className="relative px-6 border-t">
                      <Skeleton className="h-8 w-full" />
                    </ListItem>
                  </>
                )}

                {data?.organization?.membershipRequests.nodes.map((item) => (
                  <ListItem
                    className="relative px-6 border-t"
                    key={item.id}
                  >
                    <span>
                      <Link
                        className="hover:underline"
                        to={`/members/requests/${item.id}`}
                      >
                        <span className="absolute inset-0" />
                        {item.fullName}
                      </Link>
                    </span>
                    <span className="flex items-center">
                      {formatMemberRole(
                        item.membershipCode?.organizationRole ??
                          MemberRole.Member,
                      )}
                    </span>
                  </ListItem>
                ))}
              </List>
            </Card>
          </TabsContent>
          <TabsContent value="groups">
            <Card>
              <CardHeader className="bg-muted rounded-t-lg flex flex-row justify-between items-center">
                <CardTitle>Groups</CardTitle>
                <NewGroupDialog
                  members={data?.organization?.members.nodes}
                  organizationId={organizationId}
                />
              </CardHeader>
              <List>
                {fetching && (
                  <>
                    <ListItem className="relative px-6 border-t">
                      <Skeleton className="h-8 w-full" />
                    </ListItem>
                    <ListItem className="relative px-6 border-t">
                      <Skeleton className="h-8 w-full" />
                    </ListItem>
                  </>
                )}
                {data?.organization?.groups.nodes
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                  )
                  .map((item) => (
                    <ListItem
                      className="relative px-6 py-3"
                      key={item.id}
                    >
                      <div className="flex items-center gap-2">
                        <Avatar className="h-6 w-6">
                          <AvatarFallback className="text-xs">
                            {item.name.toUpperCase().slice(0, 1)}
                          </AvatarFallback>
                        </Avatar>
                        <Link
                          className="hover:underline"
                          to={`/members/groups/${item.id}`}
                        >
                          <span className="absolute inset-0" />
                          {item.name}
                        </Link>
                      </div>
                      <div className="flex items-center">
                        {item.members.totalCount} members
                      </div>
                    </ListItem>
                  ))}
              </List>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export { MemberList };
