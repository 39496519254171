import { SettingsForm } from '../components/SettingsForm';
import { Loader } from '@/components';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const SettingsQuery = graphql(`
  query SettingsQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      timezone
      logoUrl
      logoType
      defaultMapCenter {
        longitude
        latitude
      }
      defaultMapZoom
      ownerMemberId
      ownerMember {
        id
        avatarUrl
        fullName
        displayName
      }
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
        }
      }
    }
  }
`);

const Settings = () => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin,
  ]);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: SettingsQuery,
    variables: {
      organizationId,
    },
  });

  return (
    <>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        General
      </h1>
      <p className="mt-1 text-sm">
        Update your organzation details settings. Set your organization logo and
        timezone.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization && (
          <SettingsForm
            {...data.organization}
            isAdmin={isAdmin}
            organizationId={organizationId}
          />
        )}
      </Loader>
    </>
  );
};

export { Settings };
