import { CreateIncidentNoteForm } from '../components/CreateIncidentNoteForm';
import { EditIncidentContactDropdown } from '../components/EditIncidentContactDropdown';
import { EditIncidentGroupDropdown } from '../components/EditIncidentGroupDropdown';
import { EditIncidentOwnerDropdown } from '../components/EditIncidentOwnerDropdown';
import { EditIncidentPriorityDropdown } from '../components/EditIncidentPriorityDropdown';
import { EditIncidentStatusDropdown } from '../components/EditIncidentStatusDropdown';
import { IncidentDetail } from '../components/IncidentDetail';
import { IncidentLocation } from '../components/IncidentLocation';
import { IncidentReportPdf } from '../components/IncidentReportPdf';
import { IncidentUpdate } from '../components/IncidentUpdate';
import { IncidentUpdates } from '../components/IncidentUpdates';
import { ClientError, Loader } from '@/components';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { fmtPhoneNumber } from '@/utils';
import { Map } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentGql = graphql(`
  query IncidentGql($id: String!) {
    incident(id: $id) {
      id
      shortId
      organizationId
      updatedAt
      createdAt
      priority
      status
      subject
      description
      additionalData
      incidentCoordinates {
        x
        y
      }
      incidentCoordinatesGeocode {
        id
        address
        plusCode
      }
      submittedByMemberCoordinates {
        x
        y
      }
      submittedByMember {
        id
        fullName
        displayName
        avatarUrl
      }
      contactMemberId
      contactMember {
        id
        fullName
        displayName
        avatarUrl
        emailAddress
        phoneNumber
      }
      ownedByMemberId
      ownedByMember {
        id
        fullName
        displayName
        avatarUrl
      }
      ownedByGroupId
      ownedByGroup {
        id
        name
      }
      incidentUpdates {
        nodes {
          id
          type
          message
          createdAt
          previousValue
          updatedValue
          isSystemMessage
          performedByMember {
            id
            fullName
            displayName
            avatarUrl
          }
          attachments {
            nodes {
              id
              mimetype
              url
            }
          }
        }
      }
      incidentType {
        id
        name
        dataSchema
        uiSchema
        iconSvg
      }
      places {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const Incident = () => {
  const { id } = useParams() as { id: string };

  const [currentUser, isIncidentSupervisor, memberId, groups] = useAppStore(
    (state) => [
      state.currentUser,
      state.activeMembership?.isIncidentSupervisor,
      state.activeMembership?.memberId,
      state.activeMembership?.groups,
    ],
  );

  const [{ data, error, fetching }, refetch] = useQuery({
    query: IncidentGql,
    variables: {
      id,
    },
  });

  const canEditIncident =
    isIncidentSupervisor ||
    data?.incident?.ownedByMemberId === memberId ||
    groups?.includes(data?.incident?.ownedByGroupId ?? '---') ||
    false;

  return (
    <div className="grid flex-1 items-start gap-4">
      <div className="flex items-center gap-2 flex-col md:flex-row">
        <h1 className="flex-1 shrink-0 whitespace-nowrap text-2xl font-bold tracking-tight sm:grow-0 flex items-center gap-1">
          Incident Report{' '}
          {data?.incident?.shortId ? (
            <span>{data.incident.shortId}</span>
          ) : (
            <span className="animate-pulse">I-000</span>
          )}
        </h1>

        <div className="flex flex-col md:flex-row items-center gap-2 md:ml-auto">
          {fetching && !data?.incident && (
            <>
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
            </>
          )}
          {data?.incident ? (
            <>
              <EditIncidentStatusDropdown
                incidentId={id}
                onComplete={() =>
                  refetch({ requestPolicy: 'cache-and-network' })
                }
                readonly={!canEditIncident}
                status={data.incident.status}
              />
              <EditIncidentPriorityDropdown
                incidentId={id}
                onComplete={() =>
                  refetch({ requestPolicy: 'cache-and-network' })
                }
                priority={data.incident.priority}
                readonly={!canEditIncident}
              />
              <EditIncidentOwnerDropdown
                className="md:col-span-2"
                incidentId={id}
                member={data.incident.ownedByMember}
                onComplete={() =>
                  refetch({ requestPolicy: 'cache-and-network' })
                }
                readonly={!canEditIncident}
              />
              <EditIncidentGroupDropdown
                className="md:col-span-2"
                group={data.incident.ownedByGroup}
                incidentId={id}
                onComplete={() =>
                  refetch({ requestPolicy: 'cache-and-network' })
                }
                readonly={!canEditIncident}
              />
              <IncidentReportPdf incidentId={data.incident.id} />
            </>
          ) : (
            <Skeleton className="col-span-full bg-muted-foreground/10 h-8" />
          )}
        </div>
      </div>

      <div className="grid flex-1 auto-rows-max gap-4">
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.incident ? (
            <div className="grid gap-2 md:grid-cols-[1fr_250px] lg:grid-cols-3">
              <IncidentUpdates incidentId={data.incident.id} />

              <div className="grid auto-rows-max items-start gap-4 lg:col-span-2">
                <div className="order-2 md:order-1 md:col-span-8 md:pr-2">
                  <IncidentDetail
                    additionalData={data.incident.additionalData}
                    canEditIncident={canEditIncident}
                    createdAt={data.incident.createdAt}
                    dataSchema={data.incident.incidentType?.dataSchema}
                    description={data.incident.description}
                    incidentId={data.incident.id}
                    incidentType={data.incident.incidentType}
                    subject={data.incident.subject}
                    submittedByMember={data.incident.submittedByMember}
                    uiSchema={data.incident.incidentType?.uiSchema}
                  />

                  <div className="flow-root mt-6">
                    <ul className="-mb-8">
                      {data.incident.incidentUpdates.nodes.map((item) => (
                        <li key={item.id}>
                          <div className="relative pb-8">
                            <span
                              aria-hidden="true"
                              className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                            />
                            <div className="relative flex items-start space-x-3">
                              <IncidentUpdate
                                dataSchema={
                                  data.incident?.incidentType?.dataSchema
                                }
                                uiSchema={data.incident?.incidentType?.uiSchema}
                                update={item}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                      {(canEditIncident ||
                        data.incident.contactMemberId === memberId) && (
                        <li>
                          <div className="relative pb-8">
                            <div className="relative flex items-start space-x-3">
                              <div className="relative">
                                <Avatar className="h-10 w-10">
                                  <AvatarImage src={currentUser.avatarUrl} />
                                  <AvatarFallback>
                                    <Map className="h-6 w-6" />
                                  </AvatarFallback>
                                </Avatar>
                              </div>
                              <div className="min-w-0 flex-1">
                                <CreateIncidentNoteForm
                                  incidentId={data.incident.id}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="grid auto-rows-max items-start gap-4">
                <Card>
                  <CardHeader className="pb-2">
                    <CardDescription>Contact</CardDescription>
                    <CardTitle className="flex items-center gap-3 text-2xl">
                      <Avatar className="h-10 w-10">
                        <AvatarImage
                          src={
                            data.incident.contactMember?.avatarUrl ?? undefined
                          }
                        />
                        <AvatarFallback>
                          {data.incident.contactMember?.displayName?.slice(
                            0,
                            2,
                          ) ??
                            data.incident.contactMember?.fullName?.slice(
                              0,
                              2,
                            ) ??
                            'M'}
                        </AvatarFallback>
                      </Avatar>
                      <Link
                        className="font-semibold hover:underline"
                        to={`/members/${data.incident.contactMember?.id}`}
                      >
                        {data.incident.contactMember?.displayName ??
                          data.incident.contactMember?.fullName}
                      </Link>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    {data.incident.contactMember?.phoneNumber && (
                      <p className="mt-0.5 text-sm">
                        {fmtPhoneNumber(
                          data.incident.contactMember?.phoneNumber,
                        )}
                      </p>
                    )}
                    {data.incident.contactMember?.emailAddress && (
                      <p className="mt-0.5 text-sm">
                        {data.incident.contactMember?.emailAddress}
                      </p>
                    )}
                  </CardContent>
                  {canEditIncident && (
                    <CardFooter className="flex flex-row items-center justify-end border-t bg-muted/50 px-6 py-3">
                      <EditIncidentContactDropdown
                        incidentId={data.incident.id}
                        memberId={data.incident.contactMemberId}
                        onComplete={() =>
                          refetch({ requestPolicy: 'cache-and-network' })
                        }
                        organizationId={data.incident.organizationId}
                      />
                    </CardFooter>
                  )}
                </Card>

                <IncidentLocation
                  coordinates={data.incident.incidentCoordinates}
                  fetching={fetching}
                  geocode={data.incident.incidentCoordinatesGeocode}
                  places={data.incident.places}
                />
              </div>
            </div>
          ) : (
            <ClientError
              code={404}
              message="Incident does not exist"
            />
          )}
        </Loader>
      </div>
    </div>
  );
};

export { Incident };
