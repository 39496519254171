import { JoinOrganization } from '../../Account';
import { IncidentsAssignedToMe } from '../components/IncidentsAssignedToMe';
import { MyIncidents } from '../components/MyIncidents';
import { faq } from '../components/NewUserFrequentlyAskedQuestions';
import { Recent } from '../components/Recent';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAppStore } from '@/stores';
import { getHours } from 'date-fns/getHours';
import { Link } from 'react-router-dom';

const Home = () => {
  const [activeMembership, currentUser] = useAppStore((state) => [
    state.activeMembership,
    state.currentUser,
  ]);

  if (!activeMembership) {
    return (
      <div className="grid items-start gap-4 md:gap-8">
        <div>
          <h1 className="font-bold text-3xl lg:text-5xl">
            Welcome to See It Send It
          </h1>
          <p className="mt-6 max-w-prose text-xl text-muted-foreground">
            Empower your organization to protect the safety of your people and
            business. Get started with your own organization or join one already
            setup for you.
          </p>
        </div>

        <Tabs
          className="w-full"
          defaultValue="create"
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              className="cursor-pointer"
              value="create"
            >
              Create an Organization
            </TabsTrigger>
            <TabsTrigger
              className="cursor-pointer"
              value="join"
            >
              Join an Organization
            </TabsTrigger>
          </TabsList>

          <TabsContent value="create">
            <Card>
              <CardHeader>
                <CardTitle>Create an Organization</CardTitle>
                <CardDescription>
                  Unite teams and enhance safety with See It Send It.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="flex justify-center">
                  <Link to="/organization/new">
                    <Button
                      className="px-8"
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </Link>
                </div>

                {faq.map((item) => (
                  <div key={item.id}>
                    <p className="font-semibold">{item.question}</p>
                    {item.answer.map((answer, index) => (
                      <p
                        className="leading-6 mb-2"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${answer}-${index}`}
                      >
                        {answer}
                      </p>
                    ))}
                  </div>
                ))}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="join">
            <Card>
              <CardHeader>
                <CardTitle>Join an Organization</CardTitle>
                <CardDescription>
                  Use a Membership Code to request access to an organization.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <JoinOrganization />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    );
  }

  const hours = getHours(new Date());
  const greeting =
    hours > 12 ? (hours > 17 ? 'evening' : 'afternoon') : 'morning';

  return (
    <div className="grid grid-cols-1 items-start gap-4 md:gap-6">
      <div className="col-span-full grid lg:grid-cols-3 gap-4">
        <div className="col-span-2 flex items-end">
          <h1 className="text-4xl font-bold">
            Good {greeting}, {currentUser.fullName}
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 md:gap-6">
        <Recent />
        <MyIncidents />
      </div>

      <div className="">
        <IncidentsAssignedToMe />
      </div>
    </div>
  );
};

export { Home };
