import { AccountDetailsForm } from '../components/AccountDetailsForm';
import { Datetime } from '@/components/Datetime';
import { Loader } from '@/components/Loader';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useQuery } from 'urql';

const AccountDetailsGql = graphql(`
  query AccountDetailsGql {
    currentUser {
      id
      applicationRole
      timezone
      fullName
      avatarUrl
      avatarType
      metadata
      createdAt
      emailAddress
    }
  }
`);

const AccountDetails = () => {
  const [{ data, error, fetching }] = useQuery({
    query: AccountDetailsGql,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profile</CardTitle>
        <CardDescription>
          Created <Datetime datetime={data?.currentUser?.createdAt} />
        </CardDescription>
      </CardHeader>

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.currentUser && <AccountDetailsForm {...data.currentUser} />}
      </Loader>
    </Card>
  );
};

export { AccountDetails };
