import { CheckInSettingsForm } from '../components/CheckInSettingsForm';
import { Loader } from '@/components/Loader';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const CheckInSettingsGql = graphql(`
  query CheckInSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      groups {
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnCheckIn
        isAdministratorNotifiedOnCheckIn
        isSupervisorNotifiedOnCheckIn
        checkInSupervisorGroupId
        checkInSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const CheckInSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: CheckInSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Check In Settings
      </h1>
      <p className="mt-1 text-sm">
        You can add custom fields to your members to capture additional
        information.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization?.settings && (
          <CheckInSettingsForm
            groups={data.organization.groups.nodes}
            organizationId={organizationId}
            {...data.organization?.settings}
          />
        )}
      </Loader>
    </div>
  );
};

export { CheckInSettings };
