import { GuardMeSettingsForm } from '../components/GuardMeSettingsForm';
import { Loader } from '@/components/Loader';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const GuardMeSettingsGql = graphql(`
  query GuardMeSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      groups {
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnGuardme
        isAdministratorNotifiedOnGuardme
        isSupervisorNotifiedOnGuardme
        guardmeIsStartedNotificationEnabled
        guardmeIsConcludedNotificationEnabled
        guardmeIsExpiredNotificationEnabled
        guardmeIsPanicNotificationEnabled
        guardmeIsMetadataIncludedInNotification
        guardmeSupervisorGroupId
        guardmeSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const GuardMeSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: GuardMeSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        GuardMe Settings
      </h1>
      <p className="mt-1 text-sm">
        Configure who will monitor GuardMe sessions, which events they should
        receive notifications for, whether to include custom metadata fields in
        event notifications, and configuring your RapidSOS integration.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        {data?.organization?.settings && (
          <GuardMeSettingsForm
            groups={data.organization.groups.nodes}
            organizationId={organizationId}
            {...data.organization?.settings}
          />
        )}
      </Loader>
    </div>
  );
};

export { GuardMeSettings };
