import { IncidentSettingsForm } from '../components/IncidentSettingsForm';
import { Loader } from '@/components/Loader';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentSettingsGql = graphql(`
  query IncidentSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      enabledIncidentTypes {
        totalCount
      }
      groups {
        totalCount
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnIncident
        isAdministratorNotifiedOnIncident
        isSupervisorNotifiedOnIncident
        incidentSupervisorGroupId
        incidentSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const IncidentSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: IncidentSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Incident Settings
      </h1>
      <p className="mt-1 text-sm">
        Configure who will manage Incidents, and choose which incidents will be
        enabled/disabled for your members to report.
      </p>
      <Separator className="my-4" />

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        <>
          {data?.organization?.enabledIncidentTypes.totalCount === 0 && (
            <Alert variant="info">
              <AlertTitle>No Incidents Enabled!</AlertTitle>
              <AlertDescription>
                There are no incident types enabled for this organization. To
                allow members to create incidents please enable at least one
                incident type.
                <Link
                  className="text-right block"
                  to="/settings/incidents/types"
                >
                  <Button variant="secondary">Manage Incident Types</Button>
                </Link>
              </AlertDescription>
            </Alert>
          )}
          {data?.organization?.settings && (
            <IncidentSettingsForm
              groups={data.organization.groups.nodes}
              organizationId={organizationId}
              {...data.organization?.settings}
            />
          )}
        </>
      </Loader>
    </div>
  );
};

export { IncidentSettings };
