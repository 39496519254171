import { Badge } from '@/components/ui/badge';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { graphql } from '@/gql';
import { type Membership } from '@/stores/types';
import {
  Flag,
  type LucideIcon,
  Map,
  PieChart,
  Pin,
  Plus,
  Settings,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

type Nav = Array<{
  action?: {
    icon: LucideIcon;
    title: string;
    url: string;
  };
  icon: LucideIcon;
  items?: Array<{
    name: string;
    url: string;
    value?: number;
  }>;
  name: string;
  url: string;
}>;

const NavOrganizationGql = graphql(`
  query NavOrganizationGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      organizationMetric {
        incidentsActiveUrgent
        guardmesActive
        guardmesInPanic
        guardmesExpired
      }
    }
  }
`);

const NavOrganization = ({
  activeOrganization,
  pathname,
}: {
  readonly activeOrganization: Membership;
  readonly pathname: string;
}) => {
  const [{ data }] = useQuery({
    query: NavOrganizationGql,
    variables: { organizationId: activeOrganization.id },
  });

  const navItems = useMemo(() => {
    const nav: Nav = [
      {
        icon: PieChart,
        name: 'Dashboard',
        url: '/dashboard',
      },
      {
        icon: ShieldCheck,
        items: activeOrganization.isGuardMeSupervisor
          ? [
              {
                name: 'In Progress',
                url: '/guardme?filter=inProgress',
                value: data?.organization?.organizationMetric?.guardmesActive,
              },
              {
                name: 'Expired',
                url: '/guardme?filter=expired',
                value: data?.organization?.organizationMetric?.guardmesExpired,
              },
              {
                name: 'In Panic',
                url: '/guardme?filter=inPanic',
                value: data?.organization?.organizationMetric?.guardmesInPanic,
              },
            ]
          : undefined,
        name: 'GuardMe',
        url: '/guardme',
      },
      {
        action: {
          icon: Plus,
          title: 'Add incident',
          url: '/incidents/new',
        },
        icon: Flag,
        items: activeOrganization.isIncidentSupervisor
          ? [
              {
                name: 'Urgent',
                url: '/incidents?filter=urgent&filter=open&filter=inProgress',
                value:
                  data?.organization?.organizationMetric?.incidentsActiveUrgent,
              },
            ]
          : undefined,
        name: 'Incidents',
        url: '/incidents',
      },
      {
        icon: Pin,
        name: 'Check In',
        url: '/check-in',
      },
    ];

    if (activeOrganization.isCheckInSupervisor) {
      nav.push({
        action: {
          icon: Plus,
          title: 'Add check in report',
          url: '/check-in-reports/new',
        },
        icon: Map,
        name: 'Check In Reports',
        url: '/check-in-reports',
      });
    }

    nav.push({
      icon: Users,
      name: 'Members',
      url: '/members',
    });

    if (activeOrganization.isAdmin) {
      nav.push({
        icon: Settings,
        name: 'Settings',
        url: '/settings',
      });
    }

    return nav;
  }, [activeOrganization, data]);

  return (
    <SidebarGroup>
      <SidebarGroupLabel>{activeOrganization.name}</SidebarGroupLabel>
      <SidebarMenu>
        {navItems.map((item) => (
          <SidebarMenuItem key={item.name}>
            <SidebarMenuButton
              asChild
              isActive={pathname === item.url}
              tooltip={item.name}
            >
              <Link to={item.url}>
                <item.icon />
                <span>{item.name}</span>
              </Link>
            </SidebarMenuButton>
            {item.action ? (
              <SidebarMenuAction asChild>
                <Link to={item.action.url}>
                  <item.action.icon />
                  <span className="sr-only">{item.action.title}</span>
                </Link>
              </SidebarMenuAction>
            ) : null}
            {item.items?.length ? (
              <SidebarMenuSub>
                {item.items.map((subitem) => (
                  <SidebarMenuSubItem key={subitem.name}>
                    <SidebarMenuSubButton
                      asChild
                      isActive={pathname === subitem.url}
                    >
                      <Link to={subitem.url}>
                        <span>{subitem.name}</span>
                        {subitem.value ? (
                          <Badge
                            className="ml-auto"
                            variant="outline"
                          >
                            {subitem.value?.toString()}
                          </Badge>
                        ) : null}
                      </Link>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                ))}
              </SidebarMenuSub>
            ) : null}
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
};

export { NavOrganization };
