import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { config } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const ProfileAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [token, setToken] = useState('');

  const handleRefresh = async () => {
    const authToken = await getAccessTokenSilently();
    setToken(authToken);
  };

  useEffect(() => {
    if (['development', 'local'].includes(config.STAGE)) {
      handleRefresh();
    }
  });

  if (!['development', 'local'].includes(config.STAGE)) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="sm"
          variant="outline"
        >
          Get Access Token
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Access Token</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="py-4 space-y-4">
          <p className="text-xs break-all font-mono">
            {JSON.stringify({
              authorization: `Bearer ${token}`,
            })}
          </p>
          <Button
            className="w-full"
            onClick={handleRefresh}
            variant="default"
          >
            Refresh Token
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ProfileAccessToken };
