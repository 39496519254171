import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { type LucideIcon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const NavMain = ({
  items,
}: {
  readonly items: Array<{
    icon: LucideIcon;
    isActive: boolean;
    title: string;
    url: string;
  }>;
}) => {
  const pathname = useLocation().pathname;

  return (
    <SidebarMenu>
      {items.map((item) => (
        <SidebarMenuItem key={item.title}>
          <SidebarMenuButton
            asChild
            isActive={pathname === item.url}
            tooltip={item.title}
          >
            <Link to={item.url}>
              <item.icon />
              <span>{item.title}</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      ))}
    </SidebarMenu>
  );
};

export { NavMain };
