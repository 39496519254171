import { GroupAddMember } from '../components/GroupAddMember';
import { GroupDelete } from '../components/GroupDelete';
import { Empty, Loader } from '@/components';
import { List, ListItem } from '@/components/List';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { Trash } from 'lucide-react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

const GroupQuery = graphql(`
  query GroupQuery($groupId: String!) {
    group(id: $groupId) {
      id
      name
      description
      organizationId
      organization {
        id
        settings {
          id
          checkInSupervisorGroupId
          guardmeSupervisorGroupId
          incidentSupervisorGroupId
        }
        members {
          nodes {
            id
            fullName
            displayName
            isEnabled
          }
        }
      }
      members {
        totalCount
        nodes {
          id
          fullName
          displayName
          avatarUrl
        }
      }
    }
  }
`);

const RemoveMemberFromGroupDocumentQuery = graphql(`
  mutation RemoveMemberFromGroup($groupId: String!, $memberId: String!) {
    removeMemberFromGroup(input: { groupId: $groupId, memberId: $memberId }) {
      mapGroupsMember {
        organizationId
        groupId
        memberId
        createdAt
      }
    }
  }
`);

const Group = () => {
  const { groupId } = useParams() as { groupId: string };

  const [search, setSearch] = useState('');

  const [{ data, error, fetching }, refetch] = useQuery({
    query: GroupQuery,
    variables: {
      groupId,
    },
  });

  const [, removeMemberFromGroup] = useMutation(
    RemoveMemberFromGroupDocumentQuery,
  );

  const count = data?.group?.members.totalCount ?? 0;

  return (
    <div className="grid flex-1 items-start gap-4">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-semibold tracking-tight sm:grow-0">
            {data?.group ? (
              data.group.name
            ) : (
              <Skeleton className="bg-muted-foreground/10 h-8 my-0.5 w-[250px]" />
            )}
          </h1>
          <div className="ml-auto flex items-center gap-2">
            {data?.group && (
              <>
                {data?.group?.id ===
                  data?.group?.organization?.settings
                    ?.checkInSupervisorGroupId && (
                  <Badge variant="info">Check In Supervisor</Badge>
                )}
                {data?.group?.id ===
                  data?.group?.organization?.settings
                    ?.guardmeSupervisorGroupId && (
                  <Badge variant="info">GuardMe Supervisor</Badge>
                )}
                {data?.group?.id ===
                  data?.group?.organization?.settings
                    ?.incidentSupervisorGroupId && (
                  <Badge variant="info">Incident Supervisor</Badge>
                )}
              </>
            )}
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3">
          <Loader
            isLoading={fetching}
            loadingError={error}
          >
            <>
              <div className="grid auto-rows-max items-start gap-4 lg:col-span-2">
                <Card>
                  <CardHeader>
                    <CardDescription>
                      {data?.group ? (
                        data.group.description
                      ) : (
                        <Skeleton className="bg-muted-foreground/10 h-8 my-0.5 w-[250px]" />
                      )}
                    </CardDescription>
                  </CardHeader>

                  <CardContent className="space-y-6">
                    <Input
                      className="h-8"
                      onChange={(event) => setSearch(event.currentTarget.value)}
                      placeholder="Search..."
                      value={search}
                    />

                    <Empty visible={count === 0} />

                    <List>
                      {data?.group?.members.nodes
                        .filter((item) =>
                          `${item.displayName?.toLowerCase()}${item.fullName.toLowerCase()}`.includes(
                            search.toLowerCase(),
                          ),
                        )
                        .sort((a, b) =>
                          `${a.displayName}${a.fullName}` <
                          `${b.displayName}${b.fullName}`
                            ? -1
                            : 1,
                        )
                        .map((item) => (
                          <ListItem
                            className="flex items-center py-4 px-6"
                            key={`member_${item.id}`}
                          >
                            <Avatar>
                              <AvatarImage src={item.avatarUrl ?? undefined} />
                              <AvatarFallback>
                                {item.fullName.slice(0, 2)}
                              </AvatarFallback>
                            </Avatar>

                            <div className="ml-2 text-sm font-semibold">
                              <div>
                                <Link
                                  className="hover:underline"
                                  to={`/members/${item.id}`}
                                >
                                  {item.displayName ??
                                    item.fullName ??
                                    'Deleted User'}
                                </Link>
                              </div>
                              {item.displayName && (
                                <div className="text-muted-foreground">
                                  {item.fullName}
                                </div>
                              )}
                            </div>

                            <p className="ml-auto" />

                            <div className="ml-2 text-sm">
                              <Button
                                disabled={fetching}
                                onClick={async () => {
                                  if (groupId) {
                                    await removeMemberFromGroup({
                                      groupId,
                                      memberId: item.id,
                                    });
                                    refetch({
                                      requestPolicy: 'network-only',
                                    });
                                  }
                                }}
                                size="icon"
                                variant="outline"
                              >
                                <Trash className="h-4 w-4" />
                                <span className="sr-only">Remove</span>
                              </Button>
                            </div>
                          </ListItem>
                        ))}
                    </List>
                  </CardContent>
                </Card>
              </div>
              <div className="grid auto-rows-max items-start gap-4">
                {data?.group && (
                  <GroupAddMember
                    groupId={data.group.id}
                    members={data.group.organization?.members.nodes}
                    onComplete={() => {
                      refetch({
                        requestPolicy: 'network-only',
                      });
                    }}
                  />
                )}

                {data?.group && <GroupDelete groupId={data.group.id} />}
              </div>
            </>
          </Loader>
        </div>
      </div>
    </div>
  );
};

export { Group };
