import {
  RecentMapMarker,
  type RecentMapMarkerProps,
  RecentMapMarkerType,
} from './RecentMapMarker';
import { Loader } from '@/components/Loader';
import { Map, useMap } from '@/components/Map';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { graphql } from '@/gql';
import { startOfWeek } from '@/lib/time';
import { useEffect } from 'react';
import { useQuery } from 'urql';

const RecentMapGql = graphql(`
  query RecentMapGql($organizationId: String!, $createdAt_gte: Datetime!) {
    incidents(
      condition: {
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte }
      }
    ) {
      nodes {
        id
        shortId
        status
        priority
        createdAt
        incidentType {
          id
          iconSvg
        }
        contactMember {
          id
          fullName
          displayName
          avatarUrl
        }
        incidentCoordinates {
          x
          y
        }
      }
    }
    guardmes(
      condition: {
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte }
      }
    ) {
      nodes {
        id
        shortId
        status
        createdAt
        member {
          id
          fullName
          displayName
          avatarUrl
        }
        lastLocation {
          coordinates {
            x
            y
          }
        }
      }
    }
    checkIns(
      condition: {
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte }
      }
    ) {
      nodes {
        id
        shortId
        createdAt
        member {
          id
          fullName
          displayName
          avatarUrl
        }
        coordinates {
          x
          y
        }
      }
    }
  }
`);

type Props = {
  readonly defaultMapCenter: { latitude: number; longitude: number };
  readonly defaultMapZoom: number;
  readonly organizationId: string;
};

const RecentMap = ({
  defaultMapCenter,
  defaultMapZoom,
  organizationId,
}: Props) => {
  const map = useMap();
  const start = startOfWeek(Date.now());

  const [{ data, error }] = useQuery({
    query: RecentMapGql,
    variables: {
      createdAt_gte: start.toISOString(),
      organizationId,
    },
  });

  // update map if the defaultCenter changes
  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: defaultMapCenter.latitude,
        lng: defaultMapCenter.longitude,
      });
      map.setZoom(defaultMapZoom);
    }
  }, [defaultMapCenter, defaultMapZoom, map]);

  const markers: Array<Omit<RecentMapMarkerProps, 'zIndex'>> = [
    ...(data?.checkIns?.nodes.map((item) => ({
      coordinates: item.coordinates,
      createdAt: item.createdAt,
      id: item.id,
      member: item.member,
      shortId: item.shortId,
      type: RecentMapMarkerType.CHECK_IN,
    })) ?? []),
    ...(data?.guardmes?.nodes.map((item) => ({
      coordinates: item.lastLocation?.coordinates,
      createdAt: item.createdAt,
      id: item.id,
      member: item.member,
      shortId: item.shortId,
      status: item.status,
      type: RecentMapMarkerType.GUARDME,
    })) ?? []),
    ...(data?.incidents?.nodes.map((item) => ({
      coordinates: item.incidentCoordinates,
      createdAt: item.createdAt,
      icon: item.incidentType?.iconSvg,
      id: item.id,
      member: item.contactMember,
      shortId: item.shortId,
      type: RecentMapMarkerType.INCIDENT,
    })) ?? []),
  ].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

  return (
    <Card>
      <CardHeader className="p-4 pb-0 flex flex-row items-end justify-between">
        <CardTitle>This Week</CardTitle>
      </CardHeader>
      <CardContent className="p-0 pt-2">
        <Loader loadingError={error} />
        <Map
          className="h-80 rounded-b"
          defaultCenter={{
            lat: defaultMapCenter.latitude,
            lng: defaultMapCenter.longitude,
          }}
          defaultZoom={defaultMapZoom}
        >
          {markers.map((item, index) => (
            <RecentMapMarker
              {...item}
              key={item.id}
              type={item.type}
              zIndex={100 + index}
            />
          ))}
        </Map>
      </CardContent>
    </Card>
  );
};

export { RecentMap };
