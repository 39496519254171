import { ErrorBoundaryFallback } from '../../Misc';
import { ProfileAccessToken } from './ProfileAccessToken';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ErrorBoundary } from '@sentry/react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const accountNavItems = [
  {
    title: 'Profile',
    to: '/account/profile',
    value: 'profile',
  },
  {
    title: 'Contact Details',
    to: '/account/contact',
    value: 'contact-details',
  },
  {
    title: 'Memberships',
    to: '/account/memberships',
    value: 'memberships',
  },
  {
    title: 'Notifications',
    to: '/account/notifications',
    value: 'notifications',
  },
  {
    title: 'Close Account',
    to: '/account/close',
    value: 'close-account',
  },
];

const AccountLayout = () => {
  const pathname = useLocation().pathname;

  const currentPage = accountNavItems.find((item) => item.to === pathname);
  const tabValue = currentPage?.value || 'profile';

  return (
    <div className="grid items-start gap-4">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold">Account</h1>
        <ProfileAccessToken />
      </div>

      <Tabs defaultValue={tabValue}>
        <TabsList>
          {accountNavItems.map((item) => (
            <TabsTrigger
              asChild
              key={item.value}
              value={item.value}
            >
              <Link to={item.to}>
                <span>{item.title}</span>
              </Link>
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>

      <div className="space-y-4">
        <ErrorBoundary
          fallback={ErrorBoundaryFallback}
          key={pathname}
        >
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export { AccountLayout };
