import { Spinner } from '@/components';
import { CurrentUserUpdates } from '@/features/Account';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { createLogoUrl } from '@/utils';
import { type ReactNode, useEffect } from 'react';
import { useQuery } from 'urql';

const CurrentUserQuery = graphql(`
  query CurrentUser {
    currentUser {
      id
      fullName
      avatarUrl
      applicationRole
      metadata
      emailAddress
      phoneNumber
      userNotifications {
        totalCount
      }
      timezone
      memberships {
        nodes {
          id
          organizationRole
          organizationId
          isGuardmeSupervisor
          isIncidentSupervisor
          isCheckInSupervisor
          groups {
            nodes {
              id
            }
          }
          organization {
            id
            name
            logoUrl
            timezone
            plan {
              id
              name
            }
            defaultMapCenter {
              latitude
              longitude
            }
            defaultMapZoom
          }
        }
      }
    }
  }
`);

type CurrentUserProps = {
  readonly children: ReactNode;
};

const CurrentUser = ({ children }: CurrentUserProps) => {
  const [setCurrentUser, setMemberships] = useAppStore((state) => [
    state.setCurrentUser,
    state.setMemberships,
  ]);

  const [{ data, fetching }] = useQuery({
    query: CurrentUserQuery,
  });

  useEffect(() => {
    if (data?.currentUser?.memberships) {
      setCurrentUser({
        avatarUrl: data.currentUser.avatarUrl.includes(
          'https://letters.noticeable.io',
        )
          ? data.currentUser.avatarUrl.replace('png', 'svg')
          : data.currentUser.avatarUrl,
        emailAddress: data.currentUser.emailAddress ?? '',
        fullName: data.currentUser.fullName,
        id: data.currentUser.id,
        phoneNumber: data.currentUser.phoneNumber ?? '',
        role: data.currentUser.applicationRole,
        timezone: data.currentUser.timezone,
      });

      const currentUserTimezone = data.currentUser.timezone;

      setMemberships(
        data.currentUser.memberships.nodes.map((item) => ({
          defaultMapCenter: item.organization?.defaultMapCenter || {
            latitude: 0,
            longitude: 0,
          },
          defaultMapZoom: item.organization?.defaultMapZoom || 0,
          groups: item.groups.nodes.map((group) => group.id),
          id: item.organizationId,
          isAdmin: [MemberRole.Administrator, MemberRole.Owner].includes(
            item.organizationRole,
          ),
          isAuditor: false,
          isCheckInSupervisor: item.isCheckInSupervisor ?? false,
          isGuardMeSupervisor: item.isGuardmeSupervisor ?? false,
          isIncidentSupervisor: item.isIncidentSupervisor ?? false,
          isOwner: item.organizationRole === MemberRole.Owner,
          logoUrl:
            item.organization?.logoUrl || createLogoUrl(item.organizationId),
          memberId: item.id,
          name: item.organization?.name || item.organizationId,
          plan: item.organization?.plan?.name ?? 'Free',
          role: item.organizationRole,
          timezone: item.organization?.timezone || currentUserTimezone,
        })),
      );
    }
  }, [data?.currentUser, setCurrentUser, setMemberships]);

  if (fetching && data === undefined) {
    return (
      <div className="h-screen flex">
        <Spinner
          fullscreen
          logo
        />
      </div>
    );
  }

  return (
    <>
      <CurrentUserUpdates />
      {children}
    </>
  );
};

export { CurrentUser };
