import { UserNotificationItem } from '../components/UserNotificationItem';
import { UserNotificationPage } from '../components/UserNotificationPage';
import { Empty } from '@/components/Empty';
import { Loader } from '@/components/Loader';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { Info } from 'lucide-react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

const AccountNotificationsGql = graphql(`
  query AccountNotificationsGql($first: Int!) {
    currentUser {
      id
      invitations {
        totalCount
      }
      userNotifications(first: $first, orderBy: ID_DESC) {
        totalCount
        nodes {
          id
          type
          isRead
          metadata
          createdAt
          organizationId
          organization {
            id
            name
            logoUrl
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const DeleteAccountNotificationsQuery = graphql(`
  mutation DeleteAccountNotifications($notificationIds: [String]!) {
    deleteUserNotifications(input: { notificationIds: $notificationIds }) {
      query {
        currentUser {
          id
          userNotifications(condition: { isRead: false }) {
            totalCount
          }
        }
      }
    }
  }
`);

const AcknowledgeAccountNotificationsQuery = graphql(`
  mutation AcknowledgeAccountNotifications($notificationIds: [String]!) {
    acknowledgeUserNotifications(input: { notificationIds: $notificationIds }) {
      userNotifications {
        id
        isRead
      }
      query {
        currentUser {
          id
          userNotifications(condition: { isRead: false }) {
            totalCount
          }
        }
      }
    }
  }
`);

const first = 25;

const Notifications = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [{ data, error, fetching }] = useQuery({
    query: AccountNotificationsGql,
    variables: { first },
  });

  const [, deleteNotifications] = useMutation(DeleteAccountNotificationsQuery);

  const [, acknowledgeNotifications] = useMutation(
    AcknowledgeAccountNotificationsQuery,
  );

  const userNotifications = data?.currentUser?.userNotifications;

  const handleAcknowledgeNotifications = async (id: string) => {
    await acknowledgeNotifications({
      notificationIds: [id],
    });
  };

  const handleDeleteNotifications = async (id: string) => {
    await deleteNotifications({
      notificationIds: [id],
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Notifications</CardTitle>
        <CardDescription>
          Account and organization related notifications.
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          <div className="space-y-2">
            {data?.currentUser &&
              data?.currentUser?.invitations.totalCount > 0 && (
                <Alert className="bg-blue-50/50 border-blue-200 mt-6">
                  <Info className="h-6 w-6" />
                  <div className="flex items-center justify-between !transform-none">
                    <div>
                      <AlertTitle>New Invitation!</AlertTitle>
                      <AlertDescription>
                        You have {data?.currentUser?.invitations.totalCount}{' '}
                        Organization Invitations waiting for you.
                      </AlertDescription>
                    </div>
                    <Link to="/account/memberships">
                      <Button variant="ghost">View</Button>
                    </Link>
                  </div>
                </Alert>
              )}

            {userNotifications && userNotifications.nodes.length > 0 ? (
              <ul className="mb-4 border-t transition-all ease-in-out  shadow rounded-lg divide-y">
                {userNotifications.nodes.map((item) => (
                  <UserNotificationItem
                    key={item.id}
                    {...item}
                    acknowledgeNotification={handleAcknowledgeNotifications}
                    deleteNotification={handleDeleteNotifications}
                  />
                ))}

                {userNotifications.pageInfo.hasNextPage && (
                  <UserNotificationPage
                    acknowledgeNotification={handleAcknowledgeNotifications}
                    after={userNotifications.pageInfo.endCursor}
                    deleteNotification={handleDeleteNotifications}
                    first={first}
                    loadMoreRef={loadMoreRef}
                  />
                )}
              </ul>
            ) : (
              <Empty>
                <div className="space-y-2">
                  <h3 className="text-gray-900 text-3xl">No notifications</h3>
                  <p className="mt-2 text-muted-foreground">
                    New notifications will appear here once received
                  </p>
                </div>
              </Empty>
            )}
            <div ref={loadMoreRef} />
          </div>
        </Loader>
      </CardContent>
    </Card>
  );
};

export { Notifications };
