import { useIsMapProviderLoaded } from '@/components/Map';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { type Place } from '@/gql/graphql';
import { generateStaticGoogleMapUrl } from '@/lib/staticGoogleMaps';
import { useAppStore } from '@/stores';
import { Pencil } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

type PlaceDetailProps = Pick<
  Place,
  | 'center'
  | 'description'
  | 'id'
  | 'name'
  | 'radius'
  | 'spatialData'
  | 'encodedPolyline'
>;

const PlaceDetail = ({
  center,
  description,
  encodedPolyline,
  id,
  name,
  radius,
  spatialData,
}: PlaceDetailProps) => {
  const [isAdmin] = useAppStore((state) => [state.activeMembership?.isAdmin]);
  const navigate = useNavigate();
  const isMapLoaded = useIsMapProviderLoaded();

  if (!isMapLoaded) {
    return <Skeleton className="h-64" />;
  }

  // determine bounds of place
  let bounds: google.maps.LatLngBounds | null = null;

  if (google.maps.Circle && google.maps.LatLngBounds) {
    if (spatialData.geojson.type === 'Point' && google?.maps) {
      let circle: google.maps.Circle | null = new google.maps.Circle({
        center: {
          lat: spatialData.geojson.coordinates[1],
          lng: spatialData.geojson.coordinates[0],
        },
        radius,
      });

      bounds = circle.getBounds();
      circle = null;
    }

    if (spatialData.geojson.type === 'Polygon' && google?.maps) {
      bounds = new google.maps.LatLngBounds();

      for (const outer of spatialData.geojson.coordinates as number[][][]) {
        for (const inner of outer) {
          const [lng, lat] = inner;
          bounds.extend({ lat, lng });
        }
      }
    }
  }

  return (
    <article className="overflow-hidden flex border rounded-r-xl border-gray-100 bg-white shadow-md transition hover:shadow-lg">
      <img
        alt={name}
        className="h-32 w-32"
        src={generateStaticGoogleMapUrl({
          bounds,
          encodedPolyline: encodedPolyline || null,
          height: 128,
          latitude: center.latitude,
          longitude: center.longitude,
          width: 128,
        })}
      />

      <div className="bg-white px-4 py-2">
        {isAdmin ? (
          <Link
            className="hover:underline"
            to={`/settings/places/${id}/edit`}
          >
            <h3 className="text-lg font-semibold text-gray-900">{name}</h3>
          </Link>
        ) : (
          <h3 className="text-lg text-gray-900">{name}</h3>
        )}

        <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
          {description ? (
            description
          ) : (
            <span className="text-gray-400 italic">No Description</span>
          )}
        </p>
      </div>
    </article>
  );

  return (
    <div className="flex items-stretch border">
      <div>
        <img
          alt={name}
          src={generateStaticGoogleMapUrl({
            bounds,
            encodedPolyline: encodedPolyline || null,
            height: 200,
            latitude: center.latitude,
            longitude: center.longitude,
            width: 300,
          })}
        />
      </div>
      <div className="w-full p-4 flex flex-col justify-between">
        <div className="space-y-2">
          <h3 className="font-bold text-xl">{name}</h3>
          <p className="">
            {description ? (
              description
            ) : (
              <span className="text-gray-400 italic">No Description</span>
            )}
          </p>
        </div>

        <div className="flex items-center justify-end">
          {isAdmin && (
            <Button
              onClick={() => {
                navigate(`/settings/places/${id}/edit`);
              }}
              variant="secondary"
            >
              <Pencil className="h-4 w-4 mr-2" />
              Edit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export { PlaceDetail };
