import { IncidentSettingsEnabled } from '../components/IncidentSettingsEnabled';
import { Separator } from '@/components/ui/separator';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';

const IncidentTypes = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight">
        Incident Types
      </h1>
      <p className="mt-1 text-sm">
        Configure who will manage Incidents, and choose which incidents will be
        enabled/disabled for your members to report.
      </p>
      <Separator className="my-4" />

      <IncidentSettingsEnabled organizationId={organizationId} />
    </div>
  );
};

export { IncidentTypes };
