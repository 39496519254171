import { EditGroupsDialog } from '../components/EditGroupsDialog';
import { MemberActions } from '../components/MemberActions';
import { MemberCheckInList } from '../components/memberDetails/MemberCheckInList';
import { MemberGuardMeList } from '../components/memberDetails/MemberGuardMeList';
import { MemberIncidentList } from '../components/memberDetails/MemberIncidentList';
import { Datetime } from '@/components/Datetime';
import { Empty } from '@/components/Empty';
import { List, ListItem } from '@/components/List';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { parseMemberMetadata } from '@/lib/metadata';
import { fmtPhoneNumber } from '@/utils';
import { Eye } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberQuery = graphql(`
  query Member($memberId: String!) {
    member(id: $memberId) {
      id
      fullName
      displayName
      avatarUrl
      isEnabled
      organizationRole
      createdAt
      phoneNumber
      emailAddress
      metadata
      timezone
      organization {
        id
        memberMetadataFields
        groups {
          nodes {
            id
            name
          }
        }
      }
      emergencyContacts {
        nodes {
          id
          description
          phoneNumber
          priority
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

// eslint-disable-next-line complexity
const Member = () => {
  const { memberId } = useParams() as { memberId: string };

  const [{ data, error, fetching }] = useQuery({
    query: MemberQuery,
    variables: {
      memberId,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  const metadata = parseMemberMetadata(
    data?.member?.organization?.memberMetadataFields,
    data?.member?.metadata,
  );

  return (
    <div className="grid flex-1 items-start gap-4 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            <div className="flex items-center gap-2">
              <Avatar className="h-10 w-10">
                <AvatarImage src={data?.member?.avatarUrl ?? undefined} />
                <AvatarFallback>
                  {data?.member?.fullName.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <span className="grid">
                <div className="text-3xl">
                  {data?.member?.displayName ?? data?.member?.fullName}
                </div>
              </span>
            </div>
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            {data?.member && (
              <MemberActions
                disabled={!data.member.isEnabled}
                displayName={data.member.displayName}
                fullName={data.member.fullName}
                memberId={memberId}
                metadata={data.member.metadata}
                metadataFields={data.member.organization?.memberMetadataFields}
                role={data.member.organizationRole}
              />
            )}
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Member Profile</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-3">
                  <dl className="grid gap-3">
                    <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">Full Name</dt>
                      <dd>
                        {fetching ? (
                          <Skeleton className="h-4 w-[250px]" />
                        ) : (
                          data?.member?.fullName
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">Display Name</dt>
                      <dd>
                        {fetching ? (
                          <Skeleton className="h-4 w-[250px]" />
                        ) : (
                          data?.member?.displayName ?? ''
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">Timezone</dt>
                      <dd>
                        {fetching ? (
                          <Skeleton className="h-4 w-[250px]" />
                        ) : (
                          data?.member?.timezone ?? ''
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">Email Address</dt>
                      <dd>
                        {fetching ? (
                          <Skeleton className="h-4 w-[250px]" />
                        ) : (
                          <a
                            className="hover:underline"
                            href={`mail:${data?.member?.emailAddress}`}
                          >
                            {data?.member?.emailAddress}
                          </a>
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">Phone Number</dt>
                      <dd>
                        {fetching ? (
                          <Skeleton className="h-4 w-[250px]" />
                        ) : data?.member?.phoneNumber ? (
                          <a
                            className="hover:underline"
                            href={`tel:${data?.member?.phoneNumber}`}
                          >
                            {fmtPhoneNumber(data?.member?.phoneNumber)}
                          </a>
                        ) : (
                          '---'
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
                <Separator className="my-4" />

                {metadata.length > 0 && (
                  <>
                    <div className="grid gap-3">
                      <div className="font-semibold">Metadata</div>
                      <dl className="grid gap-3">
                        {metadata.map((item) => (
                          <div
                            className="flex items-center justify-between"
                            key={item.id}
                          >
                            <dt className="text-muted-foreground">
                              {item.name}
                              {item.isPrivate && (
                                <Tooltip>
                                  <TooltipTrigger className="ml-1">
                                    <Eye className="h-4 w-4 text-info" />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>Private field</p>
                                  </TooltipContent>
                                </Tooltip>
                              )}
                            </dt>
                            <dd>{item.value}</dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                    <Separator className="my-4" />
                  </>
                )}

                <div className="grid gap-3">
                  <div className="font-semibold">Emergency Contacts</div>
                  <dl className="grid gap-3">
                    {(data?.member?.emergencyContacts.nodes.length ?? 0) ===
                      0 && (
                      <Empty
                        border
                        message="No Emergency Contacts"
                      />
                    )}
                    {data?.member?.emergencyContacts.nodes
                      .sort((a, b) => (a.priority < b.priority ? -1 : 1))
                      .map((item) => (
                        <div
                          className="flex items-center justify-between"
                          key={item.id}
                        >
                          <dt className="text-muted-foreground">
                            {item.description}
                          </dt>
                          <dd>{fmtPhoneNumber(item.phoneNumber)}</dd>
                        </div>
                      ))}
                  </dl>
                </div>
              </CardContent>
            </Card>

            <Tabs defaultValue="checkin">
              <div className="flex items-center">
                <TabsList>
                  <TabsTrigger value="checkin">Check In's</TabsTrigger>
                  <TabsTrigger value="guardme">GuardMe Sessions</TabsTrigger>
                  <TabsTrigger value="incident">Incidents</TabsTrigger>
                </TabsList>
              </div>

              <TabsContent value="checkin">
                <MemberCheckInList memberId={memberId} />
              </TabsContent>
              <TabsContent value="guardme">
                <MemberGuardMeList memberId={memberId} />
              </TabsContent>
              <TabsContent value="incident">
                <MemberIncidentList memberId={memberId} />
              </TabsContent>
            </Tabs>
          </div>

          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Member Status</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="status">Joined</Label>
                    <Datetime datetime={data?.member?.createdAt} />
                  </div>
                  <div className="grid gap-3">
                    <Label htmlFor="status">Status</Label>

                    {data?.member?.isEnabled ? (
                      <p className="text-info-foreground dark:text-primary">
                        Enabled
                      </p>
                    ) : (
                      <p className="text-warning-foreground dark:text-warning">
                        Disabled
                      </p>
                    )}
                  </div>
                  <div className="grid gap-3">
                    <Label htmlFor="status">Role</Label>

                    {data?.member?.organizationRole === MemberRole.Owner && (
                      <div className="grid gap-1">
                        <div className="">Owner</div>
                        <p className="text-sm text-muted-foreground">
                          Owner have access to the entire organization. They can
                          edit and manage members, as well as all organization
                          settings, such as security and billing settings.
                        </p>
                      </div>
                    )}

                    {data?.member?.organizationRole ===
                      MemberRole.Administrator && (
                      <div className="grid gap-1">
                        <div className="">Admin</div>
                        <p className="text-sm text-muted-foreground">
                          Admins have access to the entire organization. They
                          can edit and manage members, as well as all
                          organization settings, such as security and billing
                          settings.
                        </p>
                      </div>
                    )}

                    {data?.member?.organizationRole ===
                      MemberRole.Supervisor && (
                      <div className="grid gap-1">
                        <div className="">Supervisor</div>
                        <p className="text-sm text-muted-foreground">
                          A supervisor is a member who is able to supervise
                          other members, including viewing their GuardMe
                          sessions, incidents, and check in's.
                        </p>
                      </div>
                    )}

                    {data?.member?.organizationRole === MemberRole.Member && (
                      <div className="grid gap-1">
                        <div className="">Member</div>
                        <p className="text-sm text-muted-foreground">
                          A member is an user who is a part of your
                          organization. They are able to start GuardMe sessions,
                          report incidents, and submit check in's.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Groups</CardTitle>
              </CardHeader>
              <CardContent>
                {(data?.member?.groups.nodes.length ?? 0) === 0 && (
                  <Empty
                    border
                    message="No Groups Assigned"
                  />
                )}
                <List>
                  {data?.member?.groups.nodes
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((item) => (
                      <ListItem key={item.name}>
                        <span>
                          <Link
                            className="hover:underline"
                            to={`/members/groups/${item.id}`}
                          >
                            {item.name}
                          </Link>
                        </span>
                      </ListItem>
                    ))}
                </List>
              </CardContent>
              {data?.member?.organization && (
                <CardFooter className="justify-end">
                  <EditGroupsDialog
                    allGroups={data.member.organization.groups.nodes}
                    currentGroupIds={data.member.groups.nodes.map(
                      (item) => item.id,
                    )}
                    memberId={memberId}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Member };
