import { ApplicationBreadcrumbs } from './ApplicationBreadcrumbs';
import { CurrentOrganization } from './CurrentOrganization';
import { AppSidebar } from './sidebar/AppSidebar';
import { ThemeToggleDropdown } from './ThemeToggleDropdown';
import { Separator } from '@/components/ui/separator';
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from '@/components/ui/sidebar';
import { ErrorBoundaryFallback } from '@/features/Misc';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, useLocation } from 'react-router-dom';

const ApplicationLayout = () => {
  const { pathname } = useLocation();

  return (
    <CurrentOrganization>
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset>
          <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
            <div className="w-full flex items-center gap-2 px-4">
              <SidebarTrigger className="-ml-1" />
              <Separator
                className="mr-2 h-4"
                orientation="vertical"
              />
              <ApplicationBreadcrumbs />
              <ThemeToggleDropdown className="ml-auto" />
            </div>
          </header>
          <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
            <div className="min-h-[100vh] flex-1 rounded-xl md:min-h-min">
              <ErrorBoundary
                fallback={ErrorBoundaryFallback}
                key={pathname}
              >
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </SidebarInset>
      </SidebarProvider>
    </CurrentOrganization>
  );
};

export { ApplicationLayout };

/*

*/
