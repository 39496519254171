import { MembersCheckbox } from '@/components/MembersCheckbox';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, Plus } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { array, type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const NewGroupDialogGql = graphql(`
  mutation NewGroupDialogGql(
    $organizationId: String!
    $name: String!
    $description: String!
  ) {
    createGroup(
      input: {
        organizationId: $organizationId
        name: $name
        description: $description
      }
    ) {
      group {
        id
        organizationId
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`);

const NewGroupDialogAddMemberGql = graphql(`
  mutation NewGroupDialogAddMemberGql($input: AddGroupMembersInput!) {
    addGroupMembers(input: $input) {
      group {
        id
        organizationId
        name
        description
        organization {
          id
          groups {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`);

const schema = object({
  description: string(),
  memberIds: array(nonempty(string())),
  name: nonempty(string()),
});

type NewGroupDialogProps = {
  readonly className?: string;
  readonly members?: Array<
    Pick<Member, 'id' | 'fullName' | 'displayName' | 'avatarUrl' | 'isEnabled'>
  >;
  readonly onComplete?: () => void;
  readonly organizationId: string;
  readonly redirect?: boolean;
  readonly type?: 'button' | 'link';
};

const NewGroupDialog = ({
  className,
  members = [],
  onComplete,
  organizationId,
  redirect = true,
  type = 'button',
}: NewGroupDialogProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const isAdmin = useAppStore(
    (state) => state.activeMembership?.isAdmin ?? false,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      description: '',
      memberIds: [],
      name: '',
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching }, createGroup] = useMutation(NewGroupDialogGql);

  const [{ error: addError, fetching: addFetching }, addMembers] = useMutation(
    NewGroupDialogAddMemberGql,
  );

  const combinedError = error || addError;
  const combinedFetching = fetching || addFetching;

  if (!isAdmin) {
    return null;
  }

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await createGroup({
      description: values.description,
      name: values.name,
      organizationId,
    });

    if (response.data?.createGroup?.group?.id) {
      if (values.memberIds.length > 0) {
        await addMembers({
          input: {
            groupId: response.data.createGroup.group.id,
            memberIds: values.memberIds,
          },
        });
      }

      if (onComplete) {
        onComplete();
      }

      if (redirect) {
        navigate(`/members/groups/${response.data.createGroup.group.id}`);
      }
    }
  };

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          className={cn(
            'whitespace-nowrap',
            type === 'link' && 'px-0',
            className,
          )}
          size={type === 'button' ? 'sm' : 'default'}
          variant={type === 'button' ? 'default' : 'link'}
        >
          {type === 'button' && <Plus className="h-4 w-4" />}
          New Group
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Group</DialogTitle>
          <DialogDescription>
            Once a group is created you can manage members from the group
            details page.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Tabs defaultValue="general">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="general">General</TabsTrigger>
                <TabsTrigger value="members">Members</TabsTrigger>
              </TabsList>
              <TabsContent
                className="space-y-4"
                value="general"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Group Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="About this group..."
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>
              <TabsContent
                className="space-y-4"
                value="members"
              >
                <FormField
                  control={form.control}
                  name="memberIds"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Members</FormLabel>
                      <FormControl>
                        <ScrollArea className="h-48 w-full rounded border">
                          <MembersCheckbox
                            members={members}
                            {...field}
                          />
                        </ScrollArea>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>
            </Tabs>

            <MutationError error={combinedError} />

            <DialogFooter>
              <Button
                disabled={combinedFetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={combinedFetching}
                type="submit"
              >
                {combinedFetching && (
                  <Loader className="h-6 w-6 animate-spin mr-2" />
                )}
                Add
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { NewGroupDialog };
