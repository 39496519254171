import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { NewGroupDialog } from '@/features/Members/components/NewGroupDialog';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError, PermissionDeniedError } from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Minus, Plus } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  array,
  boolean,
  type Infer,
  nonempty,
  object,
  refine,
  string,
  union,
} from 'superstruct';
import { useMutation, useQuery } from 'urql';

const CheckInReportNewGql = graphql(`
  query CheckInReportNewGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
          avatarUrl
        }
      }
      places(orderBy: [NAME_ASC]) {
        nodes {
          id
          name
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const CreateCheckInReportQuery = graphql(`
  mutation CreateCheckInReportQuery($input: CreateCheckInReportInput!) {
    createCheckInReport(input: $input) {
      checkInReport {
        id
        createdAt
        requestorMember {
          id
          displayName
          fullName
          avatarUrl
        }
        cutoffAt
        placeId
        place {
          id
          name
          description
        }
      }
    }
  }
`);

const cutoff = () =>
  refine(
    object({
      datetime: string(),
      enabled: boolean(),
    }),
    'CutoffDatetime',
    (value) => {
      if (!value.enabled) {
        return true;
      }

      const newDate = new Date(value.datetime);
      if (newDate > new Date()) {
        return true;
      }

      return 'Cutoff must be in the future';
    },
  );

const place = () =>
  refine(
    object({
      enabled: boolean(),
      id: string(),
    }),
    'Place',
    (value) => {
      if (!value.enabled) {
        return true;
      }

      if (!value) {
        return 'Missing place selection';
      }

      return true;
    },
  );

const schema = union([
  object({
    cutoff: cutoff(),
    groupIds: array(string()),
    memberIds: nonempty(array(string())),
    place: place(),
  }),
  object({
    cutoff: cutoff(),
    groupIds: nonempty(array(string())),
    memberIds: array(string()),
    place: place(),
  }),
]);

const CheckInReportNew = () => {
  const { isSupervisor, organizationId } = useAppStore((state) => ({
    isSupervisor: state.activeMembership?.isCheckInSupervisor,
    organizationId: state.activeMembership?.id,
  }));

  const [memberSearch, setMemberSearch] = useState('');

  if (!isSupervisor) {
    throw new PermissionDeniedError();
  }

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data }] = useQuery({
    query: CheckInReportNewGql,
    variables: {
      organizationId,
    },
  });

  const navigate = useNavigate();

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      cutoff: {
        datetime: '',
        enabled: false,
      },
      groupIds: [],
      memberIds: [],
      place: {
        enabled: false,
        id: '',
      },
    },
    resolver: superstructResolver(schema),
  });

  const watchGroupIds = form.watch('groupIds');
  const watchMemberIds = form.watch('memberIds');

  const [{ error }, createCheckIn] = useMutation(CreateCheckInReportQuery);

  const onSubmit = async (values: Infer<typeof schema>) => {
    // alert(JSON.stringify(values, null, 2));

    const response = await createCheckIn({
      input: {
        cutoffAt: values.cutoff.enabled
          ? new Date(values.cutoff.datetime).toISOString()
          : null,
        groupIds: values.groupIds,
        memberIds: values.memberIds,
        organizationId,
        placeId: values.place.enabled ? values.place.id : null,
      },
    });

    if (response.data?.createCheckInReport?.checkInReport?.id) {
      form.reset();
      navigate(
        `/check-in-reports/${response.data.createCheckInReport.checkInReport.id}`,
      );
    }
  };

  const onCancel = () => {
    form.reset();
    navigate(`/check-in-reports`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-semibold tracking-tight sm:grow-0">
            New Check In Report
          </h1>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
              <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl">Request Targets</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <FormField
                      control={form.control}
                      name="groupIds"
                      render={({ field: { onChange, value } }) => (
                        <FormItem>
                          <div className="mb-4">
                            <FormLabel className="text-base">
                              Groups{' '}
                              {watchGroupIds.length === 0
                                ? ''
                                : `(${watchGroupIds.length.toString()})`}
                            </FormLabel>

                            {data?.organization?.groups.nodes.length === 0 && (
                              <div className="flex flex-col items-center justify-center gap-1 border py-8">
                                No Groups Found!{' '}
                                <NewGroupDialog
                                  members={data?.organization?.members.nodes}
                                  onComplete={() => {
                                    form.reset();
                                  }}
                                  organizationId={organizationId}
                                  redirect={false}
                                  type="button"
                                />
                              </div>
                            )}

                            {(data?.organization?.groups.nodes.length ?? 0) >
                              0 && (
                              <ul className="grid grid-cols-2 gap-x-2 gap-y-1 border p-3">
                                {data?.organization?.groups.nodes.map(
                                  (item) => (
                                    <li key={item.id}>
                                      <div className="flex items-center hover:bg-muted rounded-l p-1.5">
                                        <span>{item.name}</span>
                                        <Button
                                          className="ml-auto"
                                          onClick={() => {
                                            if (value.includes(item.id)) {
                                              onChange(
                                                value.filter(
                                                  (current) =>
                                                    current !== item.id,
                                                ),
                                              );
                                            } else {
                                              onChange([...value, item.id]);
                                            }
                                          }}
                                          size="icon"
                                          type="button"
                                          variant={
                                            value.includes(item.id)
                                              ? 'destructive'
                                              : 'default'
                                          }
                                        >
                                          {value.includes(item.id) ? (
                                            <Minus className="h-5 w-5" />
                                          ) : (
                                            <Plus className="h-5 w-5" />
                                          )}
                                        </Button>
                                      </div>
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </div>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="memberIds"
                      render={({ field: { onChange, value } }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel className="text-base">
                            Members{' '}
                            {watchMemberIds.length === 0
                              ? ''
                              : `(${watchMemberIds.length.toString()})`}
                          </FormLabel>

                          <div>
                            <Input
                              onChange={(event) =>
                                setMemberSearch(event.target.value)
                              }
                              placeholder="Search Members..."
                              value={memberSearch}
                            />
                            <ScrollArea className="h-96 border-l border-b border-r pt-2">
                              <ul className="pr-2">
                                {data?.organization?.members.nodes
                                  .filter((item) => item.isEnabled)
                                  .filter(
                                    (item) =>
                                      memberSearch === '' ||
                                      item.displayName
                                        ?.toLowerCase()
                                        .startsWith(
                                          memberSearch.toLowerCase(),
                                        ) ||
                                      item.fullName
                                        ?.toLowerCase()
                                        .startsWith(memberSearch.toLowerCase()),
                                  )
                                  .map((item) => (
                                    <li key={item.id}>
                                      <div className="flex items-center hover:bg-muted rounded-l-full p-1.5">
                                        <Avatar>
                                          <AvatarImage
                                            alt={
                                              item.displayName ?? item.fullName
                                            }
                                            src={item.avatarUrl ?? undefined}
                                          />
                                          <AvatarFallback>
                                            {(
                                              item.displayName ??
                                              item.fullName ??
                                              'Deleted Member'
                                            )?.slice(0, 2)}
                                          </AvatarFallback>
                                        </Avatar>
                                        <span
                                          className={cn(
                                            'ml-2',
                                            !item.isEnabled &&
                                              'text-muted-foreground',
                                          )}
                                        >
                                          {item.displayName ??
                                            item.fullName ??
                                            'Deleted Member'}
                                          {!item.isEnabled && ' (Disabled)'}
                                        </span>
                                        <Button
                                          className="ml-auto"
                                          disabled={!item.isEnabled}
                                          onClick={() => {
                                            if (value.includes(item.id)) {
                                              onChange(
                                                value.filter(
                                                  (current) =>
                                                    current !== item.id,
                                                ),
                                              );
                                            } else {
                                              onChange([...value, item.id]);
                                            }
                                          }}
                                          size="icon"
                                          type="button"
                                          variant={
                                            value.includes(item.id)
                                              ? 'destructive'
                                              : 'default'
                                          }
                                        >
                                          {value.includes(item.id) ? (
                                            <Minus className="h-5 w-5" />
                                          ) : (
                                            <Plus className="h-5 w-5" />
                                          )}
                                        </Button>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </ScrollArea>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <MutationError error={error} />
                  </CardContent>
                </Card>

                <Card>
                  <CardContent className="p-4 flex items-center gap-2 justify-end">
                    <Button
                      onClick={onCancel}
                      type="reset"
                      variant="ghost"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !(watchGroupIds.length > 0 || watchMemberIds.length > 0)
                      }
                      type="submit"
                    >
                      Create Report
                    </Button>
                  </CardContent>
                </Card>
              </div>
              <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl">Report Options</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-6">
                      <FormField
                        control={form.control}
                        name="cutoff.enabled"
                        render={({ field: { onChange, value } }) => (
                          <FormItem>
                            <div className="grid gap-2">
                              <div className="flex items-end justify-between">
                                <FormLabel className="text-base">
                                  Enable DateTime Cutoff
                                </FormLabel>
                                <FormControl>
                                  <Switch
                                    checked={value}
                                    onCheckedChange={onChange}
                                  />
                                </FormControl>
                              </div>
                              <FormDescription>
                                The Check In request sent to members will
                                specify a date and time they should respond by.
                              </FormDescription>
                            </div>

                            <FormField
                              control={form.control}
                              name="cutoff.datetime"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="sr-only">
                                    Select datetime for cutoff
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      disabled={!value}
                                      type="datetime-local"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="place.enabled"
                        render={({ field: { onChange, value } }) => (
                          <FormItem>
                            <div className="grid gap-2">
                              <div className="flex items-end justify-between">
                                <FormLabel className="text-base">
                                  Enable Place Restriction
                                </FormLabel>
                                <FormControl>
                                  <Switch
                                    checked={value}
                                    onCheckedChange={onChange}
                                  />
                                </FormControl>
                              </div>
                              <FormDescription>
                                Members will only be allowed to complete this
                                Check In request if they are within the selected
                                geofence.
                              </FormDescription>
                            </div>

                            {data?.organization?.places.nodes.length === 0 ? (
                              <FormDescription className="text-center">
                                <Link
                                  className="text-info-foreground/80 hover:text-info-foreground"
                                  to="/settings/places"
                                >
                                  Create a place
                                </Link>
                              </FormDescription>
                            ) : (
                              <FormField
                                control={form.control}
                                name="place.id"
                                render={({
                                  field: {
                                    onChange: onChangeId,
                                    value: valueId,
                                  },
                                }) => (
                                  <FormItem>
                                    <FormLabel className="sr-only">
                                      Place
                                    </FormLabel>
                                    <Select
                                      defaultValue={valueId}
                                      disabled={!value}
                                      onValueChange={onChangeId}
                                    >
                                      <FormControl>
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a place" />
                                        </SelectTrigger>
                                      </FormControl>
                                      <SelectContent className="max-h-64">
                                        {data?.organization?.places.nodes.map(
                                          (item) => (
                                            <SelectItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </SelectItem>
                                          ),
                                        )}
                                      </SelectContent>
                                    </Select>
                                    <FormDescription className="text-right">
                                      <Link
                                        className="text-info-foreground/80 hover:text-info-foreground"
                                        to="/settings/places"
                                      >
                                        manage places
                                      </Link>
                                    </FormDescription>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            )}
                          </FormItem>
                        )}
                      />
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export { CheckInReportNew };
