import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { type Membership } from '@/stores/types';
import {
  FileType2,
  Flag,
  Grid2X2Plus,
  KeySquare,
  LifeBuoy,
  Map,
  MapPinPlus,
  Pin,
  Radio,
  Settings,
  ShieldCheck,
} from 'lucide-react';
import { Link } from 'react-router-dom';

const NavSettings = ({
  activeOrganization,
  pathname,
}: {
  readonly activeOrganization: Membership;
  readonly pathname: string;
}) => {
  const navItems = [
    {
      icon: Settings,
      title: 'General',
      url: '/settings',
    },
    {
      icon: Grid2X2Plus,
      title: 'Custom Metadata',
      url: '/settings/custom-metadata',
    },
    {
      icon: KeySquare,
      title: 'Membership Codes',
      url: '/settings/membership-Codes',
    },
    {
      icon: Pin,
      title: 'Check In Notifications',
      url: '/settings/check-in',
    },
    {
      icon: ShieldCheck,
      title: 'GuardMe Notifications',
      url: '/settings/guardme',
    },
    {
      icon: Flag,
      title: 'Incident Notifications',
      url: '/settings/incidents',
    },
    {
      icon: FileType2,
      title: 'Incident Types',
      url: '/settings/incidents/types',
    },
    {
      icon: MapPinPlus,
      title: 'Places',
      url: '/settings/places',
    },
    {
      icon: Map,
      title: 'Places Settings',
      url: '/settings/map',
    },
  ];

  const navIntegrations = [
    {
      icon: LifeBuoy,
      isPremium: true,
      title: '911 Connect',
      url: '/settings/guardme/911-integration',
    },
    {
      icon: Radio,
      title: 'Bivy Stick',
      url: '/settings/bivy-sticks',
    },
  ];

  return (
    <>
      <SidebarGroup>
        <SidebarGroupLabel>{activeOrganization.name}</SidebarGroupLabel>
        <SidebarMenu>
          {navItems.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                asChild
                isActive={pathname === item.url}
                tooltip={item.title}
              >
                <Link to={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupLabel>Integrations</SidebarGroupLabel>
        <SidebarMenu>
          {navIntegrations.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                asChild
                isActive={pathname === item.url}
                tooltip={item.title}
              >
                <Link to={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </>
  );
};

export { NavSettings };
