import { NavMain } from './NavMain';
import { NavOrganization } from './NavOrganization';
import { NavSettings } from './NavSettings';
import { NavUser } from './NavUser';
import { OrganizationSwitcher } from './OrganizationSwitcher';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from '@/components/ui/sidebar';
import { ApplicationRole } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { ArrowLeft, BadgeHelp, GlobeLock, Home } from 'lucide-react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

// This is sample data.
const navMain = [
  {
    icon: Home,
    isActive: true,
    title: 'Home',
    url: '/home',
  },
];

const AppSidebar = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const [
    currentUser,
    activeOrganization,
    organizations,
    setActiveOrganization,
  ] = useAppStore((state) => [
    state.currentUser,
    state.activeMembership,
    state.memberships,
    state.setActiveMembership,
  ]);

  const pathname = useLocation().pathname;

  return (
    <Sidebar
      collapsible="icon"
      {...props}
    >
      {pathname.startsWith('/settings') ? (
        <>
          <SidebarHeader>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <Link to="/dashboard">
                    <ArrowLeft />
                    <span>Return to Organization</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarHeader>
          <SidebarContent>
            {activeOrganization && (
              <NavSettings
                activeOrganization={activeOrganization}
                pathname={pathname}
              />
            )}
          </SidebarContent>
        </>
      ) : (
        <>
          <SidebarHeader>
            <OrganizationSwitcher
              activeOrganization={activeOrganization}
              organizations={organizations}
              setActiveOrganization={setActiveOrganization}
            />
            <NavMain items={navMain} />
          </SidebarHeader>
          <SidebarContent>
            {activeOrganization && (
              <NavOrganization
                activeOrganization={activeOrganization}
                pathname={pathname}
              />
            )}
          </SidebarContent>
        </>
      )}
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <a
                href="https://seeitsendit.com/contact"
                rel="noreferrer noopener"
                target="_blank"
              >
                <BadgeHelp />
                <span>Contact Us</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {currentUser.role === ApplicationRole.GlobalAdmin && (
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <Link to="/administration">
                  <GlobeLock />
                  <span>Administration</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
        </SidebarMenu>
        <NavUser user={currentUser} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};

export { AppSidebar };
