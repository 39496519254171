import { RapidSosIntegrationForm } from '../components/RapidSosIntegrationForm';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { OrganizationNotFoundError } from '@/lib/error';
import { useAppStore } from '@/stores';

const RapidSosIntegration = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold leading-none tracking-tight flex items-center">
        911 Integration <Badge className="ml-2">Premium Feature</Badge>
      </h1>
      <p className="mt-1 text-sm">
        With 911 integration enabled a panic during a GuardMe session will allow
        911 Emergency Services to be automatically connected with the individual
        in distress.
      </p>
      <Separator className="my-4" />

      <RapidSosIntegrationForm organizationId={organizationId} />
    </div>
  );
};

export { RapidSosIntegration };
