import { MemberRequestForm } from '../components/MemberRequestForm';
import { ClientError, Loader } from '@/components';
import { Datetime } from '@/components/Datetime';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { normalizeMemberRole } from '@/utils';
import { ChevronLeft } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const MembershipRequestQuery = graphql(`
  query MembershipRequest($requestId: String!) {
    membershipRequest(id: $requestId) {
      id
      createdAt
      updatedAt
      fullName
      phoneNumber
      emailAddress
      publicMetadata
      organizationId
      membershipCode {
        id
        code
        groupIds
        organizationRole
      }
      organization {
        id
        memberMetadataFields
        name
        groups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

const MembershipRequest = () => {
  const { id } = useParams() as { id: string };

  const [{ data, error, fetching }] = useQuery({
    query: MembershipRequestQuery,
    variables: {
      requestId: id,
    },
  });

  return (
    <div className="grid flex-1 items-start gap-4 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <Link to="/members">
            <Button
              className="h-7 w-7"
              size="icon"
              variant="outline"
            >
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
          </Link>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-semibold tracking-tight sm:grow-0">
            {data?.membershipRequest ? (
              <>Request from {data.membershipRequest.fullName}</>
            ) : (
              <Skeleton className="bg-muted-foreground/10 h-8 my-0.5 w-[250px]" />
            )}
          </h1>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <Loader
            isLoading={fetching}
            loadingError={error}
          >
            {data?.membershipRequest ? (
              <>
                <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                  <MemberRequestForm memberRequest={data.membershipRequest} />
                </div>

                <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl">Information</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
                        <div className="sm:col-span-1">
                          <dt className="text-sm text-muted-foreground">
                            Organization Name
                          </dt>
                          <dd className="mt-1">
                            {data.membershipRequest.organization?.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm text-muted-foreground">
                            Request Id
                          </dt>
                          <dd className="mt-1">{data.membershipRequest.id}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm text-muted-foreground">
                            Request Created
                          </dt>
                          <dd className="mt-1">
                            <Datetime
                              datetime={data.membershipRequest.createdAt}
                            />
                          </dd>
                        </div>
                        {data.membershipRequest.membershipCode && (
                          <>
                            <div className="sm:col-span-1">
                              <dt className="text-sm text-muted-foreground">
                                Request Code Used
                              </dt>
                              <dd className="mt-1 uppercase font-mono">
                                {data.membershipRequest.membershipCode?.code}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm text-muted-foreground">
                                Default Role
                              </dt>
                              <dd className="mt-1">
                                {normalizeMemberRole(
                                  data.membershipRequest.membershipCode
                                    ?.organizationRole,
                                )}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm text-muted-foreground">
                                Default Groups
                              </dt>
                              <dd className="mt-1">
                                {data.membershipRequest.membershipCode?.groupIds
                                  .length === 0 && '---'}
                                {data.membershipRequest.membershipCode?.groupIds.map(
                                  (groupId) => {
                                    const group =
                                      data.membershipRequest?.organization?.groups.nodes.find(
                                        (node) => {
                                          return node.id === groupId;
                                        },
                                      );
                                    return group ? (
                                      <div key={group.id}>{group.name}</div>
                                    ) : null;
                                  },
                                )}
                              </dd>
                            </div>
                          </>
                        )}
                      </dl>
                    </CardContent>
                  </Card>
                </div>
              </>
            ) : (
              <ClientError
                code={404}
                message="Membership request does not exist"
              />
            )}
          </Loader>
        </div>
      </div>
    </div>
  );
};

export { MembershipRequest };
